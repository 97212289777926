import { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaSearch, FaTimes } from 'react-icons/fa';

const Search = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const searchRef = useRef(null);

  // Close search on escape key
  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === 'Escape') {
        setIsOpen(false);
      }
    };

    window.addEventListener('keydown', handleEscape);
    return () => window.removeEventListener('keydown', handleEscape);
  }, []);

  // Focus search input when opened
  useEffect(() => {
    if (isOpen && searchRef.current) {
      searchRef.current.focus();
    }
  }, [isOpen]);

  const isElementVisible = (element) => {
    const style = window.getComputedStyle(element);
    return style.display !== 'none' && 
           style.visibility !== 'hidden' && 
           style.opacity !== '0' &&
           element.offsetWidth > 0 && 
           element.offsetHeight > 0;
  };

  const getVisibleText = (element) => {
    // Skip hidden elements
    if (!isElementVisible(element)) return '';

    // Skip elements that are typically not user-facing
    if (element.tagName === 'SCRIPT' || 
        element.tagName === 'STYLE' || 
        element.tagName === 'META' || 
        element.tagName === 'LINK' ||
        element.classList?.contains('hidden') ||
        element.classList?.contains('sr-only')) {
      return '';
    }

    // Get text content
    let text = element.textContent.trim();
    
    // Skip empty text
    if (!text) return '';

    // Skip if text is just whitespace or special characters
    if (/^[\s\W]+$/.test(text)) return '';

    return text;
  };

  const isRedundantResult = (element, text, results) => {
    // Check if this text is already in results
    const isDuplicateText = results.some(result => 
      result.text === text || 
      result.text.includes(text) || 
      text.includes(result.text)
    );

    // Check if element is a child of an already included element
    const isChildOfIncluded = results.some(result => 
      element.parentElement === result.element || 
      result.element.contains(element)
    );

    // Check if element is a parent of an already included element
    const isParentOfIncluded = results.some(result => 
      element === result.element.parentElement || 
      element.contains(result.element)
    );

    return isDuplicateText || isChildOfIncluded || isParentOfIncluded;
  };

  const performSearch = (query) => {
    if (!query.trim()) {
      setSearchResults([]);
      return;
    }

    const searchableElements = document.querySelectorAll('h1, h2, h3, h4, h5, h6, p, li, a, span');
    const results = [];

    searchableElements.forEach((element) => {
      const text = getVisibleText(element);
      if (text && text.toLowerCase().includes(query.toLowerCase())) {
        // Skip redundant results
        if (isRedundantResult(element, text, results)) return;

        // Get the parent heading or section for context
        let context = '';
        let parent = element.parentElement;
        while (parent && !context) {
          if (parent.tagName.match(/^H[1-6]$/)) {
            context = parent.textContent.trim();
          }
          parent = parent.parentElement;
        }

        results.push({
          element,
          text: text,
          context: context || 'Main Content',
        });
      }
    });

    setSearchResults(results);
  };

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    performSearch(query);
  };

  const scrollToResult = (result) => {
    result.element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    result.element.classList.add('highlight');
    setTimeout(() => {
      result.element.classList.remove('highlight');
    }, 4000); // Increased from 2000 to 4000ms
    setIsOpen(false);
  };

  return (
    <>
      <button
        onClick={() => setIsOpen(true)}
        className="search-button hidden"
        aria-label="Search website"
        title="Search website"
      >
        <FaSearch className="w-5 h-5" />
        <span className="sr-only">Search</span>
      </button>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-start justify-center p-4 pt-20"
          >
            <motion.div
              initial={{ y: -50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: -50, opacity: 0 }}
              className="bg-white dark:bg-gray-800 rounded-lg shadow-xl w-full max-w-2xl"
            >
              <div className="p-4 border-b dark:border-gray-700">
                <div className="flex items-center">
                  <input
                    ref={searchRef}
                    type="text"
                    value={searchQuery}
                    onChange={handleSearch}
                    placeholder="Search website..."
                    className="w-full px-4 py-2 text-lg border rounded-lg focus:outline-none focus:ring-2 focus:ring-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                  />
                  <button
                    onClick={() => setIsOpen(false)}
                    className="ml-2 p-2 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
                  >
                    <FaTimes className="w-6 h-6" />
                  </button>
                </div>
              </div>

              <div className="max-h-[60vh] overflow-y-auto">
                {searchResults.length > 0 ? (
                  <ul className="divide-y dark:divide-gray-700">
                    {searchResults.map((result, index) => (
                      <li
                        key={index}
                        onClick={() => scrollToResult(result)}
                        className="p-4 hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer"
                      >
                        <p className="text-gray-800 dark:text-gray-200">{result.text}</p>
                        <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">{result.context}</p>
                      </li>
                    ))}
                  </ul>
                ) : searchQuery ? (
                  <div className="p-4 text-center text-gray-500 dark:text-gray-400">
                    No results found
                  </div>
                ) : null}
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      <style>{`
        .highlight {
          animation: highlight 4s ease-out;
        }

        @keyframes highlight {
          0% {
            background-color: rgba(59, 130, 246, 0.5);
          }
          50% {
            background-color: rgba(59, 130, 246, 0.5);
          }
          100% {
            background-color: transparent;
          }
        }
      `}</style>
    </>
  );
};

export default Search; 