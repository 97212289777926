export const personalInfo = {
  name: "Devyn Miller",
  title: "Graduate Student",
  email: "devmiller@chapman.edu", // Replace with your actual email
  location: "CA, USA",
  bio: `Hi! I'm a graduate student pursuing a master's degree in Electrical Engineering & Computer Science and recently completed my master's degree in Behavioral & Computational Economics, bothat Chapman University. I focus on using AI and machine learning to improve health outcomes, working as a Graduate Research Assistant to optimize resource allocation and developing a SaaS project to provide AI-assisted nutritional guidance in Chapman’s InterPhase Incubator program. I love applying tech solutions to real-world challenges!`,
  shortBio: "Always curious, always building. AI, economics, and everything in between!",
  social: {
    github: "https://github.com/devyn-miller",
    linkedin: "https://www.linkedin.com/in/devyn-c-miller/",
  },
  resumeUrl: "/assets/projects/resume.pdf", // Add your resume file later
};

export const education = [
  {
    degree: "Master's in Electrical Engineering and Computer Science",
    school: "Chapman University",
    year: "2026",
    description: "Focus on statistical modeling and machine learning",
  },
  {
    degree: "Master's in Behavioral and Computational Economics",
    school: "Chapman University",
    year: "2024",
    description: "Specialized in health economics and data analysis",
  },
];

export const interests = [
  "Bayesian Statistics",
  "Machine Learning",
  "Data Visualization",
  "Statistical Programming",
  "Computational Methods",
];
