import { useState } from 'react';
import { Link } from 'react-router-dom';
import ScrollAnimation from './ScrollAnimation';
import { blogCategories, blogPosts, getPostsByCategory, searchPosts } from '../data/blog';
import { FaSearch, FaClock, FaTag } from 'react-icons/fa';
import BlogArchive from './BlogArchive';
// import Newsletter from './Newsletter';

const BlogCard = ({ post }) => (
  <ScrollAnimation>
    <div
      className="bg-white dark:bg-gray-800 rounded-xl shadow-lg overflow-hidden hover:shadow-xl transition-shadow duration-300"
    >
      <div className="p-6">
        <div className="flex flex-wrap items-center gap-3 mb-3">
          <div className="flex items-center">
            <FaClock className="text-primary-500 dark:text-primary-400 mr-2" />
            <span className="text-sm text-gray-600 dark:text-gray-400">{post.readTime}</span>
          </div>
          <div className="flex flex-wrap gap-2">
            {post.categories.map((category) => (
              <span
                key={category}
                className="inline-flex items-center text-xs px-3 py-1.5 rounded-full 
                         bg-primary-100 dark:bg-primary-900 text-primary-700 dark:text-primary-300
                         transition-colors duration-200 hover:bg-primary-200 dark:hover:bg-primary-800"
              >
                {blogCategories.find(cat => cat.id === category)?.name}
              </span>
            ))}
          </div>
        </div>
        
        <h3 className="text-xl font-semibold mb-2 bg-gradient-to-r from-primary-600 to-accent-500 dark:from-primary-400 dark:to-accent-400 text-transparent bg-clip-text">
          {post.title}
        </h3>
        
        <p className="text-gray-600 dark:text-gray-300 mb-4">
          {post.preview}
        </p>
        
        <div className="flex items-center justify-between">
          <span className="text-sm text-gray-500 dark:text-gray-400">
            {new Date(post.date).toLocaleDateString()}
          </span>
          <Link
            to={`/blog/${post.id}`}
            className="inline-block"
          >
            <ScrollAnimation whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
              <button
                className="px-4 py-2 bg-primary-500 text-white rounded-lg hover:bg-primary-600 transition-colors duration-300"
              >
                Read More
              </button>
            </ScrollAnimation>
          </Link>
        </div>
      </div>
    </div>
  </ScrollAnimation>
);

const Blog = () => {
  const [selectedCategory, setSelectedCategory] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  
  const featuredPosts = blogPosts.filter(post => post.featured);
  
  const filteredPosts = searchQuery
    ? searchPosts(searchQuery)
    : selectedCategory
    ? getPostsByCategory(selectedCategory)
    : blogPosts.filter(post => !post.featured);

  return (
    <ScrollAnimation>
      <section id="blog" className="min-h-screen py-20 bg-gradient-to-b from-white to-sky-50 dark:from-gray-900 dark:to-gray-800 transition-colors duration-300">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <ScrollAnimation delay={0.1}>
            <div className="text-center mb-12">
              <h2 className="text-4xl font-bold mb-4 bg-gradient-to-r from-primary-600 to-accent-500 dark:from-primary-400 dark:to-accent-400 text-transparent bg-clip-text">
                Blog
              </h2>
              <p className="text-gray-600 dark:text-gray-300 max-w-2xl mx-auto">
                Insights and thoughts on machine learning, AI, health economics, and data science
              </p>
            </div>
          </ScrollAnimation>

          <div className="flex flex-col lg:flex-row gap-8">
            {/* Main Content */}
            <div className="flex-1">
              {/* Search and Filter */}
              <ScrollAnimation delay={0.2}>
                <div className="mb-8 flex flex-col sm:flex-row gap-4 justify-between items-center">
                  <div className="relative flex-1 max-w-md">
                    <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                    <input
                      type="text"
                      placeholder="Search posts..."
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100 focus:ring-2 focus:ring-primary-500 focus:border-transparent"
                    />
                  </div>
                  
                  <div className="flex gap-2 flex-wrap justify-center">
                    <button
                      onClick={() => setSelectedCategory('')}
                      className={`px-4 py-2 rounded-lg transition-colors duration-300 ${
                        selectedCategory === ''
                          ? 'bg-primary-500 text-white'
                          : 'bg-gray-200 dark:bg-gray-700 text-gray-700 dark:text-gray-300'
                      }`}
                    >
                      All
                    </button>
                    {blogCategories.map((category) => (
                      <button
                        key={category.id}
                        onClick={() => setSelectedCategory(category.id)}
                        className={`px-4 py-2 rounded-lg transition-colors duration-300 ${
                          selectedCategory === category.id
                            ? 'bg-primary-500 text-white'
                            : 'bg-gray-200 dark:bg-gray-700 text-gray-700 dark:text-gray-300'
                        }`}
                      >
                        {category.name}
                      </button>
                    ))}
                  </div>
                </div>
              </ScrollAnimation>

              {/* Featured Posts */}
              {!searchQuery && !selectedCategory && (
                <ScrollAnimation delay={0.3}>
                  <div className="mb-12">
                    <h3 className="text-2xl font-semibold mb-6 text-gray-900 dark:text-gray-100">
                      Featured Posts
                    </h3>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                      {featuredPosts.map((post) => (
                        <BlogCard key={post.id} post={post} />
                      ))}
                    </div>
                  </div>
                </ScrollAnimation>
              )}

              {/* All Posts */}
              <ScrollAnimation delay={0.4}>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6">
                  {filteredPosts.map((post) => (
                    <BlogCard key={post.id} post={post} />
                  ))}
                </div>
              </ScrollAnimation>

              {filteredPosts.length === 0 && (
                <ScrollAnimation delay={0.5}>
                  <div className="text-center py-12">
                    <p className="text-gray-600 dark:text-gray-400">
                      {/* No posts found. Try adjusting your search or filter criteria. */}
                    </p>
                  </div>
                </ScrollAnimation>
              )}
            </div>

            {/* Sidebar */}
            <div className="lg:w-80 space-y-8">
              {/* Newsletter */}
              {/* <div className="mt-12">
                <Newsletter />
              </div> */}

              {/* Archive */}
              <ScrollAnimation delay={0.7}>
                <div className="bg-white dark:bg-gray-800 rounded-xl p-6 shadow-lg">
                  <h3 className="text-xl font-semibold mb-4 text-gray-900 dark:text-gray-100">
                    Archive
                  </h3>
                  <BlogArchive />
                </div>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </section>
    </ScrollAnimation>
  );
};

export default Blog;
