import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTopOnNavigate = () => {
  const { pathname, hash } = useLocation();

  useEffect(() => {
    // Only scroll to top when:
    // 1. The route changes
    // 2. There is no hash in the URL (which would indicate scrolling to a specific section)
    // 3. We're not navigating to one of the scrollable section routes
    const isSectionRoute = ['/about', '/experience', '/skills', '/projects', '/writing', '/blog', '/contact'].includes(pathname);
    
    if (!hash && !isSectionRoute) {
      window.scrollTo(0, 0);
    }
  }, [pathname, hash]);

  return null;
};

export default ScrollToTopOnNavigate; 