import { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence, useInView } from 'framer-motion';
import ScrollAnimation from './ScrollAnimation';
import {
  SiPython,
  SiR,
  SiTensorflow,
  SiPytorch,
  SiScikitlearn,
  SiPandas,
  SiNumpy,
  SiJupyter,
  SiReact,
  SiTypescript,
  SiJavascript,
  SiTailwindcss,
  SiMongodb,
  SiPostgresql,
  SiDocker,
  SiGit,
  SiSwift,
  SiXcode,
  SiStreamlit,
  SiLatex,
  SiNodedotjs,
  SiExpress,
  SiGoogleappsscript,
  SiFramer,
  SiFirebase,
  SiVite,
  SiAxios,
} from 'react-icons/si';
import { FaChartBar, FaCode, FaBrain, FaDatabase, FaMobile, FaTools, FaGamepad } from 'react-icons/fa';
import Masonry from 'react-masonry-css';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

const projectOrder = {
  'Image Segmentation Using U-Net': 1,
  'Stress Management iOS/WatchOS App': 5,
  'Video Colorization Using Keras Tuner': 3,
  "Gambler's Ruin Dynamic Program": 4,
  'HyperGain - Hypertrophy Web App': 6,
  'DiffPixel': 7,
  'DebateSpark': 8,
  'Meta Score Trends in IMDB Movies': 10,
  'Bayesian vs Frequentist Analysis': 9,
  'Marriage Rates Using Logistic Regression': 2,
  '2048 Game Variations': 12,
  'Naughty or Nice AI Chatbot': 13,
  'Resume': 13,
  'Customer Inertia Analysis': 14,
  'Cryptocurrency Dashboard': 11,
  'GitHub Repository Analysis Tool': 16
};

const skills = [
  {
    name: 'Python',
    icon: SiPython,
    categories: ['Programming', 'Data Science'],
    projects: [
      { name: 'Image Segmentation Using U-Net', id: 1 },
      { name: 'Video Colorization Using Keras Tuner', id: 3 },
      { name: "Gambler's Ruin Dynamic Program", id: 4 },
      { name: 'Customer Inertia Analysis', id: 14 },
      { name: 'Cryptocurrency Dashboard', id: 11 }
    ]
  },
  {
    name: 'R',
    icon: SiR,
    categories: ['Programming', 'Data Science'],
    projects: [
      { name: 'Bayesian vs Frequentist Analysis', id: 9 },
      { name: 'Meta Score Trends in IMDB Movies', id: 10 },
      { name: 'Marriage Rates Using Logistic Regression', id: 2 },
      { name: 'Cryptocurrency Price Analysis', id: 15 }
    ]
  },
  {
    name: 'TensorFlow',
    icon: SiTensorflow,
    categories: ['Programming', 'Data Science'],
    projects: [
      { name: 'Image Segmentation Using U-Net', id: 1 },
      { name: 'Video Colorization Using Keras Tuner', id: 3 }
    ]
  },
  {
    name: 'PyTorch',
    icon: SiPytorch,
    categories: ['Programming', 'Data Science'],
    projects: [
      { name: 'Customer Inertia Analysis', id: 14 }
    ] 
  }, 
  {
    name: 'Scikit-learn',
    icon: SiScikitlearn,
    categories: ['Programming', 'Data Science'],
    projects: [
      { name: 'Image Segmentation Using U-Net', id: 1 }
    ]
  },
  {
    name: 'Pandas',
    icon: SiPandas,
    categories: ['Programming', 'Data Science'],
    projects: [
      { name: 'Meta Score Trends in IMDB Movies', id: 10 },
      { name: 'Cryptocurrency Dashboard', id: 11 }
    ]
  },
  {
    name: 'NumPy',
    icon: SiNumpy,
    categories: ['Programming', 'Data Science'],
    projects: [
      { name: 'Image Segmentation Using U-Net', id: 1 }
    ]
  },
  {
    name: 'Jupyter',
    icon: SiJupyter,
    categories: ['Programming', 'Data Science'],
    projects: [
      { name: 'Image Segmentation Using U-Net', id: 1 },
      { name: 'Video Colorization Using Keras Tuner', id: 3 }
    ]
  },
  {
    name: 'React',
    icon: SiReact,
    categories: ['Web Development'],
    projects: [
      { name: 'DiffPixel', id: 7 },
      { name: 'DebateSpark', id: 8 },
      { name: 'HyperGain - Hypertrophy Web App', id: 6 },
      { name: '2048 Game Variations', id: 12 },
      { name: 'Naughty or Nice AI Chatbot', id: 13 },
      { name: 'GitHub Repository Analysis Tool', id: 16 }
    ]
  },
  {
    name: 'TypeScript',
    icon: SiTypescript,
    categories: ['Web Development'],
    projects: [
      { name: 'DebateSpark', id: 8 },
      { name: 'DiffPixel', id: 7 },
      { name: '2048 Game Variations', id: 12 },
      { name: 'Naughty or Nice AI Chatbot', id: 13 },
      { name: 'GitHub Repository Analysis Tool', id: 16 }
    ]
  },
  {
    name: 'JavaScript',
    icon: SiJavascript,
    categories: ['Web Development'],
    projects: [
      { name: 'DebateSpark', id: 8 },
      { name: 'DiffPixel', id: 7 },
      { name: 'HyperGain - Hypertrophy Web App', id: 6 },
      { name: '2048 Game Variations', id: 12 },
      { name: 'Naughty or Nice AI Chatbot', id: 13 }
    ]
  },
  {
    name: 'TailwindCSS',
    icon: SiTailwindcss,
    categories: ['Web Development'],
    projects: [
      { name: 'DiffPixel', id: 7 },
      { name: 'DebateSpark', id: 8 },
      { name: 'HyperGain - Hypertrophy Web App', id: 6 },
      { name: '2048 Game Variations', id: 12 },
      { name: 'Naughty or Nice AI Chatbot', id: 13 },
      { name: 'GitHub Repository Analysis Tool', id: 16 }
    ]
  },
  {
    name: 'Node.js',
    icon: SiNodedotjs,
    categories: ['Web Development'],
    projects: [
      { name: 'DebateSpark', id: 8 },
      { name: 'DiffPixel', id: 7 },
      { name: 'HyperGain - Hypertrophy Web App', id: 6 },
      { name: 'GitHub Repository Analysis Tool', id: 16 }
    ]
  },
  {
    name: 'Express',
    icon: SiExpress,
    categories: ['Web Development'],
    projects: [
      { name: 'DebateSpark', id: 8 },
      { name: 'HyperGain - Hypertrophy Web App', id: 6 },
      { name: 'GitHub Repository Analysis Tool', id: 16 }
    ]
  },
  {
    name: 'MongoDB',
    icon: SiMongodb,
    categories: ['Web Development']
  },
  {
    name: 'PostgreSQL',
    icon: SiPostgresql,
    categories: ['Data Science', 'Web Development']
  },
  {
    name: 'Docker',
    icon: SiDocker,
    categories: ['DevOps'],
    projects: [
      { name: 'Image Segmentation Using U-Net', id: 1 },
      { name: 'Video Colorization Using Keras Tuner', id: 3 }
    ]
  },
  {
    name: 'Git',
    icon: SiGit,
    categories: ['DevOps'],
    projects: [
      { name: 'Image Segmentation Using U-Net', id: 1 },
      { name: 'Video Colorization Using Keras Tuner', id: 3 },
      { name: 'DiffPixel', id: 7 },
      { name: 'DebateSpark', id: 8 },
      { name: '2048 Game Variations', id: 12 },
      { name: 'Naughty or Nice AI Chatbot', id: 13 },
      { name: 'HyperGain - Hypertrophy Web App', id: 6 },
      { name: 'Meta Score Trends in IMDB Movies', id: 10 },
      { name: 'Bayesian vs Frequentist Analysis', id: 9 },
      { name: 'Marriage Rates Using Logistic Regression', id: 2 },
      { name: 'Customer Inertia Analysis', id: 14 },
      { name: 'Cryptocurrency Dashboard', id: 11 },
      { name: 'Cryptocurrency Price Analysis', id: 15 }
    ]
  },
  {
    name: 'Game Development',
    icon: FaGamepad,
    categories: ['Programming'],
    projects: [
      { name: '2048 Game Variations', id: 12 },
      { name: 'Naughty or Nice AI Chatbot', id: 13 }
    ]
  },
  {
    name: 'Machine Learning',
    icon: FaBrain,
    categories: ['Data Science', 'Artificial Intelligence'],
    projects: [
      { name: 'Image Segmentation Using U-Net', id: 1 },
      { name: 'Video Colorization Using Keras Tuner', id: 3 },
      { name: 'Naughty or Nice AI Chatbot', id: 13 },
      { name: 'Customer Inertia Analysis', id: 14 },
      { name: 'Cryptocurrency Price Analysis', id: 15 }
    ]
  },
  {
    name: 'Swift',
    icon: SiSwift,
    categories: ['Programming'],
    projects: [
      { name: 'Stress Management iOS/WatchOS App', id: 5 }
    ]
  },
  {
    name: 'Xcode',
    icon: SiXcode,
    categories: ['Programming'],
    projects: [
      { name: 'Stress Management iOS/WatchOS App', id: 5 }
    ]
  },
  {
    name: 'Streamlit',
    icon: SiStreamlit,
    categories: ['Programming', 'Data Science'],
    projects: [
      { name: "Gambler's Ruin Dynamic Program", id: 4 },
      { name: 'Cryptocurrency Dashboard', id: 11 }
    ]
  },
  {
    name: 'LaTeX',
    icon: SiLatex,
    categories: ['Documentation'],
    projects: [],
    resume: true
  },
  {
    name: 'SQL',
    icon: SiPostgresql,
    categories: ['Database'],
    projects: [
      { name: 'HyperGain - Hypertrophy Web App', id: 6 }
    ]
  },
  {
    name: 'Google Apps Script',
    icon: SiGoogleappsscript,
    categories: ['Programming', 'Web Development'],
  },
  {
    name: 'Framer Motion',
    icon: SiFramer,
    categories: ['Web Development'],
    projects: [
      { name: 'HyperGain - Hypertrophy Web App', id: 6 }
    ], 
  },
  {
    name: 'Firebase',
    icon: SiFirebase,
    categories: ['Web Development', 'Database'],
  },
  {
    name: 'Vite',
    icon: SiVite,
    categories: ['Web Development', 'DevOps'],
    projects: [
      { name: '2048 Game Variations', id: 12 },
      { name: 'Naughty or Nice AI Chatbot', id: 13 }
    ]
  },
  {
    name: 'API Integration',
    icon: FaCode,
    categories: ['Web Development', 'Programming'],
    projects: [
      { name: 'DebateSpark', id: 8 },
      { name: 'Naughty or Nice AI Chatbot', id: 13 },
      { name: 'Cryptocurrency Dashboard', id: 11 }
    ]
  },
  {
    name: 'Deep Learning',
    icon: FaBrain,
    categories: ['Data Science', 'Artificial Intelligence'],
    projects: [
      { name: 'Video Colorization Using Keras Tuner', id: 3 },
      { name: 'Image Segmentation Using U-Net', id: 1 }
    ]
  },
  {
    name: 'Computer Vision',
    icon: FaCode,
    categories: ['Data Science', 'Artificial Intelligence'],
    projects: [
      { name: 'Video Colorization Using Keras Tuner', id: 3 },
      { name: 'Image Segmentation Using U-Net', id: 1 }
    ]
  },
  {
    name: 'Power BI',
    icon: FaChartBar,
    categories: ['Data Science'],
    projects: []
  },
  {
    name: 'REST API',
    icon: FaCode,
    categories: ['Web Development', 'Programming'],
    projects: [
      { name: 'DebateSpark', id: 8 },
      { name: 'Naughty or Nice AI Chatbot', id: 13 },
      { name: 'Cryptocurrency Dashboard', id: 11 },
      { name: 'GitHub Repository Analysis Tool', id: 16 }
    ]
  },
  {
    name: 'Axios',
    icon: SiAxios,
    categories: ['Web Development'],
    projects: [
      { name: 'GitHub Repository Analysis Tool', id: 16 }
    ]
  },
  {
    name: 'LangChain',
    icon: FaCode,
    categories: ['Programming', 'Artificial Intelligence'],
    projects: [
      // { name: 'Chatbot Development', id: 17 },
      // { name: 'Document Analysis', id: 18 }
    ]
  },
];

const categories = ['All', 'Programming', 'Web Development', 'Data Science', 'DevOps', 'Artificial Intelligence'];

const useWindowWidth = () => {
  const [width, setWidth] = useState(typeof window !== 'undefined' ? window.innerWidth : 768);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return width;
};

const SkillCard = ({ skill, onProjectClick, isExpanded, onToggleExpand, resume }) => {
  const hasProjects = skill.projects && skill.projects.length > 0;

  const handleResumeClick = () => {
    window.open("https://drive.google.com/file/d/1wayzgQrm0pB9XRAW9DaPbiamLT7pMkg9/view?usp=sharing", '_blank');
  };

  return (
    <div className="h-full py-1.5">
      <motion.div
        className="h-full bg-white dark:bg-gray-800 rounded-xl shadow-soft hover:shadow-md transition-all duration-300"
      >
        <div className="p-4">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-3 flex-1 min-w-0">
              <div className="w-10 h-10 flex-shrink-0 flex items-center justify-center rounded-lg bg-primary-50 dark:bg-gray-700">
                <skill.icon className="w-5 h-5 text-primary-500 dark:text-primary-400" />
              </div>
              <h3 className="text-base font-medium text-gray-900 dark:text-white truncate">
                {skill.name}
              </h3>
            </div>
            {(hasProjects || resume) && (
              <motion.button
                onClick={() => onToggleExpand()}
                className="p-1 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full transition-colors flex-shrink-0 ml-2"
                animate={{ rotate: isExpanded ? 180 : 0 }}
              >
                <svg
                  className="w-5 h-5 text-gray-500 dark:text-gray-400"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </motion.button>
            )}
          </div>
        </div>

        <AnimatePresence>
          {(isExpanded && hasProjects) && (
            <motion.div
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: "auto", opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
              transition={{ duration: 0.2 }}
              className="overflow-hidden border-t border-gray-100 dark:border-gray-700"
            >
              <div className="p-4 space-y-2">
                {skill.projects.map((project) => (
                  <motion.button
                    key={project.id}
                    onClick={() => onProjectClick(project.id)}
                    className="w-full group"
                    whileHover={{ x: 4 }}
                  >
                    <div className="flex items-center justify-between p-2 rounded-lg transition-all duration-200 hover:bg-primary-50 dark:hover:bg-primary-900/30 border border-transparent hover:border-primary-200 dark:hover:border-primary-700">
                      <div className="flex items-start gap-2 min-w-0">
                        <div className="w-1 h-1 rounded-full bg-primary-500 mt-2 flex-shrink-0"></div>
                        <span className="text-sm text-gray-700 dark:text-gray-300 group-hover:text-primary-600 dark:group-hover:text-primary-400 line-clamp-2 text-left">
                          {project.name}
                        </span>
                      </div>
                      <svg
                        className="w-4 h-4 text-primary-500 dark:text-primary-400 opacity-0 group-hover:opacity-100 transition-opacity flex-shrink-0 ml-2"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                        />
                      </svg>
                    </div>
                  </motion.button>
                ))}
              </div>
            </motion.div>
          )}
          {(isExpanded && resume) && (
            <motion.div
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: "auto", opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
              transition={{ duration: 0.2 }}
              className="overflow-hidden border-t border-gray-100 dark:border-gray-700"
            >
              <div className="p-4 space-y-2">
                <motion.button
                  onClick={handleResumeClick}
                  className="w-full group"
                  whileHover={{ x: 4 }}
                >
                  <div className="flex items-center justify-between p-2 rounded-lg transition-all duration-200 hover:bg-primary-50 dark:hover:bg-primary-900/30 border border-transparent hover:border-primary-200 dark:hover:border-primary-700">
                    <div className="flex items-start gap-2 min-w-0">
                      <div className="w-1 h-1 rounded-full bg-primary-500 mt-2 flex-shrink-0"></div>
                      <span className="text-sm text-gray-700 dark:text-gray-300 group-hover:text-primary-600 dark:group-hover:text-primary-400 line-clamp-2 text-left">
                        Resume
                      </span>
                    </div>
                    <svg
                      className="w-4 h-4 text-primary-500 dark:text-primary-400 opacity-0 group-hover:opacity-100 transition-opacity flex-shrink-0 ml-2"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                      />
                    </svg>
                  </div>
                </motion.button>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
    </div>
  );
};

const SkillCategory = ({ category, items, isExpanded, onToggle }) => {
  const [showAll, setShowAll] = useState(false);
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth < 768;
  
  const displayItems = (!showAll && isMobile) ? items.slice(0, 5) : items;
  const hasMore = items.length > 5;

  return (
    <motion.div
      variants={{
        hidden: { opacity: 0, y: 20 },
        visible: { opacity: 1, y: 0 }
      }}
      className="bg-white dark:bg-gray-800 rounded-xl shadow-soft p-4 sm:p-6 border border-gray-100 dark:border-gray-700 mb-2"
    >
      <button
        onClick={onToggle}
        className="w-full flex items-center justify-between text-left md:cursor-default md:pointer-events-none"
      >
        <h3 className="text-lg sm:text-xl font-semibold text-gray-900 dark:text-white">
          {category}
        </h3>
        <span className="md:hidden">
          {isExpanded ? <FaChevronUp className="w-4 h-4" /> : <FaChevronDown className="w-4 h-4" />}
        </span>
      </button>
      
      <div className={`mt-3 flex flex-wrap gap-1.5 sm:gap-2 ${!isExpanded ? 'hidden md:flex' : 'flex'}`}>
        {displayItems.map((skill) => (
          <span
            key={skill}
            className="text-xs sm:text-sm bg-primary-100 dark:bg-primary-900/30 text-primary-600 dark:text-primary-400 px-2 sm:px-3 py-1 rounded-full"
          >
            {skill}
          </span>
        ))}
        
        {hasMore && isMobile && isExpanded && (
          <button
            onClick={(e) => {
              e.stopPropagation();
              setShowAll(!showAll);
            }}
            className="mt-2 py-1 px-3 text-xs font-medium text-primary-600 dark:text-primary-400 bg-primary-50 dark:bg-primary-900/20 rounded-full hover:bg-primary-100 dark:hover:bg-primary-900/30 transition-colors w-full"
          >
            {showAll ? 'Show Less' : `Show ${items.length - 5} More Skills`}
          </button>
        )}
      </div>
    </motion.div>
  );
};

const Skills = () => {
  const containerRef = useRef(null);
  const isInView = useInView(containerRef, { once: true, margin: "-20% 0px -20% 0px" });
  const [expandedCategories, setExpandedCategories] = useState(new Set());
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [expandedSkills, setExpandedSkills] = useState(new Set());
  const gridRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      const grid = gridRef.current;
      if (!grid) return;

      const items = Array.from(grid.children);
      const rowHeight = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-auto-rows'));
      const rowGap = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-row-gap'));

      items.forEach(item => {
        const contentHeight = item.querySelector('.h-full').offsetHeight;
        const rowSpan = Math.ceil((contentHeight + rowGap) / (rowHeight + rowGap));
        item.style.gridRowEnd = `span ${rowSpan}`;
      });
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [expandedSkills]);

  const filteredSkills = skills.filter(
    (skill) =>
      selectedCategory === 'All' || skill.categories.includes(selectedCategory)
  );

  const handleProjectClick = (projectId) => {
    const projectsSection = document.getElementById('projects');
    if (projectsSection) {
      projectsSection.scrollIntoView({ behavior: 'smooth' });
    }

    setTimeout(() => {
      const event = new CustomEvent('openProject', { detail: projectId });
      window.dispatchEvent(event);
    }, 500);
  };

  const toggleSkill = (skillName) => {
    setExpandedSkills(prev => {
      const newSet = new Set(prev);
      if (newSet.has(skillName)) {
        newSet.delete(skillName);
      } else {
        newSet.add(skillName);
      }
      return newSet;
    });
  };

  const expandAll = () => {
    const skillsWithProjectsOrResume = filteredSkills
      .filter(skill => (skill.projects && skill.projects.length > 0) || skill.resume)
      .map(skill => skill.name);
    setExpandedSkills(new Set(skillsWithProjectsOrResume));
  };

  const collapseAll = () => {
    setExpandedSkills(new Set());
  };

  const hasExpandableSkills = filteredSkills.some(skill => skill.projects && skill.projects.length > 0);

  const breakpointColumnsObj = {
    default: 4,
    1100: 3,
    700: 2,
    500: 1
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.5,
        when: "beforeChildren",
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { 
      opacity: 0,
      y: 20
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: [0.22, 1, 0.36, 1]
      }
    }
  };

  return (
    <ScrollAnimation>
      <section id="skills" className="py-20 bg-gradient-to-b from-white to-sky-50 dark:from-gray-900 dark:to-gray-800 transition-colors duration-300">
        <div className="container mx-auto px-4 sm:px-6">
          <motion.div
            ref={containerRef}
            initial="hidden"
            animate={isInView ? "visible" : "hidden"}
            variants={containerVariants}
          >
            <motion.div 
              variants={itemVariants}
              className="text-center mb-12"
            >
              <h2 className="text-4xl font-display font-bold mb-6 bg-gradient-to-r from-primary-600 to-accent-500 dark:from-primary-400 dark:to-accent-400 text-transparent bg-clip-text">
                Skills
              </h2>
              <p className="text-gray-600 dark:text-gray-300 max-w-2xl mx-auto">
                Some of the tools I use and technical skills I'm developing!
              </p>
            </motion.div>

            <motion.div 
              variants={itemVariants}
              className="mb-8"
            >
              <div className="flex flex-wrap justify-center gap-3 mb-6">
                {categories.map((category, index) => (
                  <motion.button
                    key={category}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3, delay: index * 0.1 }}
                    onClick={() => setSelectedCategory(category)}
                    className={`px-5 py-2 rounded-xl text-sm font-medium transition-all duration-300 shadow-sm hover:shadow-md
                      ${selectedCategory === category
                        ? 'bg-primary-500 text-white shadow-md hover:bg-primary-600'
                        : 'bg-white dark:bg-gray-800 text-gray-600 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700 border border-gray-200 dark:border-gray-700 hover:border-primary-200 dark:hover:border-primary-700'
                      }`}
                  >
                    {category}
                  </motion.button>
                ))}
              </div>

              {/* Expand/Collapse Controls */}
              {hasExpandableSkills && (
                <div className="flex justify-center mb-8">
                  <button
                    onClick={() => expandedSkills.size === 0 ? expandAll() : collapseAll()}
                    className="inline-flex items-center gap-2 px-4 py-2 rounded-xl bg-white dark:bg-gray-800 text-sm text-primary-500 dark:text-primary-400 hover:text-primary-600 dark:hover:text-primary-300 shadow-soft hover:shadow-md transition-all duration-300 border border-gray-100 dark:border-gray-700"
                  >
                    <svg 
                      className="w-4 h-4" 
                      fill="none" 
                      viewBox="0 0 24 24" 
                      stroke="currentColor"
                    >
                      <path 
                        strokeLinecap="round" 
                        strokeLinejoin="round" 
                        strokeWidth={2} 
                        d={expandedSkills.size === 0 
                          ? "M19 9l-7 7-7-7" 
                          : "M5 15l7-7 7 7"
                        } 
                      />
                    </svg>
                    {expandedSkills.size === 0 ? 'Expand to See Projects' : 'Collapse Skills'}
                  </button>
                </div>
              )}
            </motion.div>

            <Masonry
              breakpointCols={breakpointColumnsObj}
              className="flex -ml-4 w-auto"
              columnClassName="pl-4 bg-clip-padding"
            >
              {filteredSkills.map((skill, index) => (
                <motion.div
                  key={skill.name}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.3, delay: index * 0.05 }}
                >
                  <SkillCard
                    skill={skill}
                    onProjectClick={handleProjectClick}
                    isExpanded={expandedSkills.has(skill.name)}
                    onToggleExpand={() => toggleSkill(skill.name)}
                    resume={skill.resume}
                  />
                </motion.div>
              ))}
            </Masonry>

            {/* Empty State */}
            {filteredSkills.length === 0 && (
              <motion.div
                variants={itemVariants}
                className="text-center py-12"
              >
                <p className="text-gray-500 dark:text-gray-400">
                  No skills found in this category.
                </p>
              </motion.div>
            )}
          </motion.div>
        </div>
      </section>
    </ScrollAnimation>
  );
};

export default Skills;
