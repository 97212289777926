import { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Dialog } from '@headlessui/react';
import { FaGithub, FaExternalLinkAlt, FaTimes, FaFilePdf, FaPlayCircle, FaCode, FaFileCode } from 'react-icons/fa';
import ScrollAnimation from './ScrollAnimation';
import { useInView } from 'framer-motion';
import { useRef } from 'react';

// Import project images
import projectOne from '../assets/projects/project-one.png';
import projectTwo from '../assets/projects/project-two.png';
import projectThree from '../assets/projects/project-three.png';
import projectFour from '../assets/projects/project-four.png';
import projectFive from '../assets/projects/project-five.png';
import projectSix from '../assets/projects/project-six.png';
import diffPixelImg from '../assets/projects/diffimage.png';
import debateSparkImg from '../assets/projects/debatespark.png';
import imdbImg from '../assets/projects/imdb.png';
import bayesFreqImg from '../assets/projects/bayesfreq.png';
import minemergeImg from '../assets/projects/minemerge.png';
import classic2048Img from '../assets/projects/classic2048.png';
import naughtyNiceImg from '../assets/projects/naughty-nice.png';
import cryptoDashImg from '../assets/projects/crypto-dash.png';
import inertiaImg from '../assets/projects/inertia.png';
import btcProject from '../assets/projects/btc-final-project.png';
import ghRepoFinderImg from '../assets/projects/gh-repo-finder.png';

// Import project PDFs
import projectOnePdf from '../assets/projects/project-one.pdf';
import projectTwoPdf from '../assets/projects/project-two.pdf';
import projectThreePdf from '../assets/projects/project-three.pdf';
import projectFivePdf from '../assets/projects/project-five.pdf';
import bayesianPdf from '../assets/projects/Bayesian_frequentist.pdf';
import imdbPdf from '../assets/projects/DM Meta Score Trends in IMDB Movies Dataset Final Draft.pdf';
import inertiaPdf from '../assets/projects/inertia.pdf';
import btcProjectPdf from '../assets/projects/CPSC 540 Final Project - Cryptocurrency Price Analysis.pdf';

// Import R code files
import bayesianRCode from '../assets/projects/bayesian-frequentist.R';
import imdbRCode from '../assets/projects/imdb.R';

// Import projects data
import { projects as projectsData } from '../data/projects';

const projects = [
  {
    id: 1,
    title: 'Image Segmentation Using UNet',
    description: 'Deep learning model for image segmentation using UNet architecture',
    longDescription: `Advanced image segmentation project utilizing UNet architecture for precise object detection and segmentation. 
                     Implemented with TensorFlow and Keras, achieving high accuracy in pixel-level segmentation tasks.`,
    tags: ['Python', 'TensorFlow', 'Keras', 'Deep Learning'],
    image: projectOne,
    github: 'https://github.com/devyn-miller/the-final-assignment2-cpsc542',
    pdf: projectOnePdf,
    technologies: {
      'Core': ['Python', 'TensorFlow', 'Keras'],
      'Data Processing': ['NumPy', 'Scikit-learn'],
      'Visualization': ['Matplotlib']
    },
    categories: ['Machine Learning'],
    challenges: [
      'Implementing complex UNet architecture',
      'Handling large image datasets',
      'Optimizing model performance',
      'Managing memory usage during training'
    ],
    solutions: [
      'Developed efficient data loading pipeline',
      'Implemented data augmentation techniques',
      'Optimized model architecture for better performance',
      'Used gradient checkpointing for memory efficiency'
    ],
    slug: 'image-segmentation-unet'
  },
  {
    id: 2,
    title: 'Marriage Rates Using Logistic Regression',
    description: 'Statistical analysis of marriage rates using logistic regression in R',
    longDescription: `Comprehensive analysis of marriage rates using logistic regression models in R. 
                     Utilized ggplot2 for data visualization and statistical analysis. 
                     Project awarded "Best Analysis" at SoCal R Hackathon (April 2024).`,
    tags: ['R', 'Statistics', 'Data Analysis'],
    image: projectTwo,
    pdf: projectTwoPdf,
    technologies: {
      'Core': ['R'],
      'Visualization': ['ggplot2'],
      'Analysis': ['Statistical Computing']
    },
    categories: ['Data Science', 'Statistical Analysis'],
    challenges: [
      'Processing large demographic datasets',
      'Handling missing data',
      'Creating meaningful visualizations',
      'Interpreting complex statistical results'
    ],
    solutions: [
      'Implemented robust data cleaning procedures',
      'Used advanced imputation techniques',
      'Created interactive visualizations',
      'Developed comprehensive statistical analysis'
    ],
    slug: 'marriage-rates-logistic-regression'
  },
  {
    id: 3,
    title: 'Video Colorization Using Keras Tuner',
    description: 'Deep learning system for automatic video colorization',
    longDescription: `Advanced deep learning project for automatic colorization of black and white videos. 
                     Utilizes Keras Tuner for hyperparameter optimization and OpenCV for video processing.`,
    tags: ['Python', 'Deep Learning', 'Computer Vision'],
    image: projectThree,
    github: 'https://github.com/devyn-miller/CPSC542_final_project',
    pdf: projectThreePdf,
    technologies: {
      'Core': ['Python', 'TensorFlow', 'Keras'],
      'Video Processing': ['OpenCV'],
      'Data Analysis': ['Pandas', 'Seaborn']
    },
    categories: ['Machine Learning'],
    challenges: [
      'Processing large video datasets',
      'Maintaining temporal consistency',
      'Optimizing colorization quality',
      'Managing computational resources'
    ],
    solutions: [
      'Implemented efficient frame processing pipeline',
      'Developed temporal consistency algorithms',
      'Used transfer learning for better results',
      'Optimized memory usage during processing'
    ],
    slug: 'video-colorization-keras-tuner'
  },
  {
    id: 4,
    title: 'Gambler\'s Ruin Dynamic Program',
    description: 'Interactive web app for gambling probability analysis',
    longDescription: `Dynamic programming application for analyzing gambling probabilities and optimal strategies. 
                     Built with Streamlit for an interactive web interface.`,
    tags: ['Python', 'Streamlit', 'Data Analysis'],
    image: projectFour,
    github: 'https://github.com/EconomicScienceInstitute/GamblersRuin/',
    technologies: {
      'Core': ['Python', 'Streamlit'],
      'Computation': ['NumPy'],
      'Visualization': ['Matplotlib']
    },
    categories: ['Data Science'],
    challenges: [
      'Implementing complex probability calculations',
      'Creating interactive visualizations',
      'Optimizing computation speed',
      'Designing intuitive user interface'
    ],
    solutions: [
      'Developed efficient dynamic programming algorithm',
      'Created interactive Streamlit components',
      'Implemented caching for better performance',
      'Designed user-friendly interface'
    ],
    slug: 'gamblers-ruin'
  },
  {
    id: 5,
    title: 'Stress Management iOS/WatchOS App',
    description: 'Health app using Heart Rate Variability for stress monitoring',
    longDescription: `Comprehensive health monitoring application for iOS and WatchOS, utilizing Heart Rate Variability 
                     for stress level tracking and management.`,
    tags: ['Swift', 'iOS', 'WatchOS', 'HealthKit'],
    image: projectFive,
    pdf: projectFivePdf,
    technologies: {
      'Core': ['Swift', 'SwiftUI'],
      'Frameworks': ['WatchKit', 'HealthKit'],
      'UI': ['UIKit']
    },
    challenges: [
      'Integrating HealthKit data',
      'Ensuring real-time monitoring',
      'Managing battery consumption',
      'Cross-device synchronization'
    ],
    solutions: [
      'Implemented efficient HealthKit integration',
      'Optimized background processing',
      'Developed battery-efficient algorithms',
      'Created robust sync mechanism'
    ],
    slug: 'stress-management-app'
  },
  {
    id: 6,
    title: 'HyperGain - Hypertrophy Web App',
    description: 'Full-stack web application for workout tracking and planning',
    longDescription: `Modern web application for tracking and planning hypertrophy workouts. 
                     Built with Next.js and MongoDB, featuring a responsive design and smooth animations.`,
    tags: ['Next.js', 'React', 'TypeScript', 'MongoDB'],
    image: projectSix,
    video: 'https://drive.google.com/file/d/1ZWWGhfbeOaQ67FwmJ4q_L-lQUILUwTM3/view',
    technologies: {
      'Frontend': ['Next.js', 'React', 'TypeScript', 'Tailwind CSS', 'Framer Motion'],
      'Backend': ['MongoDB', 'Mongoose'],
      'Tools': ['ESLint', 'Prettier']
    },
    categories: ['Web Development'],
    challenges: [
      'Managing complex state across components',
      'Implementing responsive design',
      'Optimizing database queries',
      'Creating smooth animations'
    ],
    solutions: [
      'Used advanced state management patterns',
      'Implemented mobile-first design approach',
      'Optimized MongoDB queries and indexes',
      'Created custom animation system'
    ],
    slug: 'hypergain'
  },
  {
    id: 7,
    title: 'DiffPixel',
    description: 'Advanced image comparison and analysis tool',
    longDescription: `DiffPixel is a sophisticated image comparison tool that leverages computer vision techniques to identify and highlight differences between images. Built with React and modern image processing libraries, it provides pixel-perfect comparison capabilities with an intuitive user interface.`,
    tags: ['React', 'Computer Vision', 'Image Processing'],
    image: diffPixelImg,
    github: 'https://github.com/devyn-miller/diff-image-checker',
    demo: 'https://devyn-miller.github.io/diff-image-checker/',
    technologies: {
      'Frontend': ['React', 'TailwindCSS'],
      'Image Processing': ['Canvas API', 'WebGL'],
      'Tools': ['Vite', 'Jest']
    },
    categories: ['Web Development', 'Machine Learning'],
    challenges: [
      'Implementing efficient image comparison algorithms',
      'Optimizing performance for large images',
      'Creating an intuitive user interface',
      'Handling various image formats and sizes'
    ],
    solutions: [
      'Developed custom pixel comparison algorithms',
      'Implemented progressive loading and processing',
      'Created interactive visualization overlays',
      'Added support for multiple comparison modes'
    ],
    slug: 'diffpixel'
  },
  {
    id: 8,
    title: 'DebateSpark',
    description: 'AI-powered debate and discussion platform',
    longDescription: `DebateSpark is a full-stack application that facilitates structured debates and discussions using AI moderation. Note: Initial load may take a moment as the AI models are loaded. Built with Node.js, Express, and React, featuring real-time updates and intelligent topic matching.`,
    tags: ['Node.js', 'Express', 'React', 'API'],
    image: debateSparkImg,
    demo: 'https://devyn-miller.github.io/random-debate-generator/',
    technologies: {
      'Frontend': ['React', 'TailwindCSS'],
      'Backend': ['Node.js', 'Express', 'RESTful APIs'],
      'Database': ['MongoDB'],
      'AI': ['OpenAI API', 'Natural Language Processing']
    },
    categories: ['Web Development', 'Machine Learning'],
    challenges: [
      'Managing real-time debate interactions',
      'Implementing fair AI moderation',
      'Optimizing initial load performance',
      'Scaling for concurrent debates'
    ],
    solutions: [
      'Used WebSocket for real-time updates',
      'Implemented AI moderation queue system',
      'Added progressive loading states',
      'Deployed with containerization for scalability'
    ],
    slug: 'debatespark'
  },
  {
    id: 9,
    title: 'Bayesian vs Frequentist Analysis',
    description: 'Comparative analysis of mental well-being effects using Bayesian and Frequentist approaches',
    longDescription: `Conducted comprehensive analysis comparing Bayesian and Frequentist approaches to study the effects of 
                     petting corgis on mental well-being. The study utilized advanced statistical methods including Bayesian 
                     Generalized Linear Models and traditional Frequentist t-tests to produce reliable estimates for causal relationships.`,
    tags: ['R', 'Statistics', 'Bayesian Analysis'],
    image: bayesFreqImg,
    pdf: bayesianPdf,
    rCode: bayesianRCode,
    technologies: {
      'Core': ['R', 'Stan'],
      'Statistics': ['Bayesian Analysis', 'Frequentist Analysis'],
      'Visualization': ['ggplot2']
    },
    categories: ['Data Science', 'Statistical Analysis'],
    challenges: [
      'Implementing complex statistical models',
      'Comparing different statistical paradigms',
      'Processing large datasets',
      'Ensuring statistical validity'
    ],
    solutions: [
      'Developed robust statistical framework',
      'Implemented parallel processing for efficiency',
      'Created detailed comparative analysis',
      'Validated causal relationships through multiple methods'
    ],
    slug: 'bayesian-vs-frequentist'
  },
  {
    id: 10,
    title: 'Meta Score Trends in IMDB Movies',
    description: 'Time series analysis of IMDB movie ratings using advanced statistical methods',
    longDescription: `Analyzed meta score trends in top 1,000 films using Generalized Additive Models, 
                     STL decomposition, and ACF analysis. The project delivered actionable insights for 
                     streaming platforms and content creators to optimize release schedules based on 
                     historical rating patterns.`,
    tags: ['R', 'Time Series', 'Data Analysis'],
    image: imdbImg,
    pdf: imdbPdf,
    rCode: imdbRCode,
    technologies: {
      'Core': ['R'],
      'Analysis': ['Time Series', 'GAM', 'STL'],
      'Visualization': ['ggplot2', 'plotly']
    },
    categories: ['Data Science', 'Statistical Analysis'],
    challenges: [
      'Handling complex time series data',
      'Implementing advanced statistical models',
      'Creating meaningful visualizations',
      'Drawing actionable insights'
    ],
    solutions: [
      'Developed custom time series models',
      'Implemented robust data cleaning',
      'Created interactive visualizations',
      'Provided actionable recommendations'
    ],
    slug: 'imdb-meta-score-trends'
  },
  {
    id: 11,
    title: 'Cryptocurrency Dashboard',
    description: 'Real-time cryptocurrency tracking dashboard with comprehensive market analysis',
    longDescription: `A real-time cryptocurrency tracking dashboard providing comprehensive market analysis 
                     and visualization of cryptocurrency data using the CryptoCompare API. Features interactive 
                     charts, technical analysis tools, and real-time market insights.`,
    tags: ['Python', 'Streamlit', 'API Integration', 'Data Visualization', 'Plotly', 'Pandas'],
    image: cryptoDashImg,
    github: 'https://github.com/devyn-miller/crypto-dashboard',
    demo: 'https://devyn-miller-crypto-dashboard-dashboard-mhno6d.streamlit.app/',
    technologies: {
      'Core': ['Python', 'Streamlit', 'CryptoCompare API'],
      'Data & Visualization': ['Pandas', 'Plotly', 'Matplotlib'],
      'Development': ['Git', 'pip']
    },
    highlights: [
      "Real-time cryptocurrency market data tracking and analysis",
      "Interactive technical analysis with multiple indicators",
      "Comprehensive market overview with global statistics",
      "Advanced data visualization using Plotly and custom charts",
      "Efficient API integration with caching and error handling"
    ],
    categories: ['Data Science', 'Web Development'],
    slug: 'crypto-dashboard'
  },
  {
    id: 12,
    title: '2048 Game Variations',
    description: 'Two unique implementations of the classic 2048 puzzle game with advanced features',
    longDescription: `Developed two distinct 2048 game implementations showcasing advanced React, TypeScript, and game development techniques. 
                     Each version offers unique themes, features, and technical implementations.`,
    tags: ['React', 'TypeScript', 'Game Development', 'Web App'],
    images: [minemergeImg, classic2048Img],
    github: 'https://github.com/devyn-miller/2048-games',
    demo: 'https://devyn-miller.github.io/minemerge/',
    technologies: {
      'Frontend': ['React', 'TypeScript', 'TailwindCSS'],
      'State Management': ['React Context', 'Custom Reducers'],
      'Tools': ['Vite', 'ESLint']
    },
    projects: [
      {
        title: 'MineMerge',
        description: 'A Minecraft-themed 2048 game with dynamic grid and advanced features',
        github: 'https://github.com/devyn-miller/minemerge',
        demo: 'https://devyn-miller.github.io/minemerge/',
        technologies: {
          'Core': ['React', 'TypeScript'],
          'Styling': ['TailwindCSS'],
          'State Management': ['React Context', 'useReducer'],
          'Additional': ['Vite', 'ESLint']
        },
        challenges: [
          'Implementing Minecraft-themed block merging logic',
          'Creating responsive game grid',
          'Managing complex game state'
        ],
        solutions: [
          'Developed custom grid manipulation algorithms',
          'Implemented advanced state management',
          'Created responsive design with touch support'
        ]
      },
      {
        title: 'Classic 2048',
        description: 'Feature-rich 2048 game with custom theming and advanced state management',
        github: 'https://github.com/devyn-miller/2048-dec2024',
        demo: 'https://devyn-miller.github.io/2048-dec2024/',
        technologies: {
          'Core': ['React', 'TypeScript'],
          'Styling': ['TailwindCSS'],
          'State Management': ['Custom Reducer', 'Immutable Updates'],
          'Additional': ['Responsive Design', 'Theme System']
        },
        challenges: [
          'Implementing efficient game logic',
          'Creating a flexible theming system',
          'Optimizing performance'
        ],
        solutions: [
          'Developed modular game logic with TypeScript',
          'Implemented real-time theme customization',
          'Optimized rendering with React hooks'
        ]
      }
    ],
    categories: ['Web Development', 'Game Development', 'Games'],
    challenges: [
      'Implementing complex game mechanics',
      'Creating responsive and interactive game interfaces',
      'Managing game state efficiently'
    ],
    solutions: [
      'Developed advanced state management techniques',
      'Implemented custom game logic with TypeScript',
      'Created responsive designs with touch support'
    ],
    slug: '2048-game-variations'
  },
  {
    id: 13,
    title: 'Naughty or Nice AI Chatbot',
    description: 'Interactive AI-powered chatbot that determines if users have been naughty or nice this year',
    longDescription: `Holiday-themed AI chatbot that engages users in conversation to determine their naughty or nice status. 
                     Built with React, TypeScript, and OpenAI's GPT API for natural language processing.`,
    tags: ['React', 'TypeScript', 'API'],
    image: naughtyNiceImg,
    github: 'https://github.com/devyn-miller/hohoho',
    demo: 'https://devyn-miller.github.io/hohoho/',
    technologies: {
      'Frontend': ['React', 'TypeScript', 'TailwindCSS'],
      'AI': ['OpenAI API', 'Natural Language Processing'],
      'State Management': ['React Context', 'Custom Hooks'],
      'Tools': ['Vite', 'ESLint']
    },
    categories: ['Web Development', 'Artificial Intelligence', 'Games', 'Machine Learning'],
    challenges: [
      'Implementing dynamic conversation flow',
      'Creating engaging AI responses',
      'Managing complex scoring system',
      'Ensuring responsive design'
    ],
    solutions: [
      'Developed adaptive question selection algorithm',
      'Integrated OpenAI for contextual responses',
      'Created real-time score tracking system',
      'Implemented mobile-first responsive design'
    ],
    slug: 'naughty-nice-chatbot'
  },
  {
    id: 14,
    title: 'Customer Inertia Analysis',
    description: 'Research on customer loyalty and price insensitivity in high switching cost industries',
    longDescription: `A comprehensive analysis of customer inertia, loyalty, and price insensitivity in industries with high switching costs. 
                     The project explores how customers resist switching to competitors despite better alternatives, analyzing the psychological 
                     biases behind this behavior and how companies can leverage it for business advantage. Through mathematical modeling and 
                     simulations, the project examines how inertia factors, price sensitivity, and smoothing factors influence market share 
                     dynamics over multiple time periods.`,
    tags: ['Mathematical Modeling', 'Statistical Analysis', 'Market Research', 'Behavioral Economics', 'Data Visualization'],
    image: inertiaImg,
    pdf: inertiaPdf,
    demo: 'https://esd3finalgraphs-jxugirv63ym7ubyje95bxd.streamlit.app/',
    technologies: {
      'Analysis': ['Mathematical Modeling', 'Statistical Analysis', 'Regression Analysis'],
      'Research': ['Behavioral Economics', 'Market Research', 'Empirical Studies'],
      'Visualization': ['Data Visualization', 'Graphical Models']
    },
    categories: ['Data Science', 'Machine Learning'],
    challenges: [
      'Modeling complex customer behavior and psychological biases',
      'Simulating market share dynamics under different conditions',
      'Analyzing the impact of auto-renewal mechanisms',
      'Quantifying the effects of price sensitivity and inertia'
    ],
    solutions: [
      'Developed dynamic pricing models for customer behavior simulation',
      'Implemented sensitivity analysis across multiple time periods',
      'Created visualization models for market share evolution',
      'Integrated empirical research with theoretical frameworks',
      'Quantified the relationship between inertia and market stability'
    ],
    highlights: [
      'Found that higher inertia (0.85) leads to more stable market shares',
      'Demonstrated how price sensitivity (0.3) creates market share volatility',
      'Analyzed impact of smoothing factors on market responsiveness',
      'Integrated behavioral economics theories with empirical data',
      'Provided actionable insights for business strategy'
    ],
    slug: 'customer-inertia-analysis'
  },
  {
    id: 15,
    title: 'Cryptocurrency Price Analysis',
    description: 'Final project for CPSC 540 (Machine Learning) analyzing cryptocurrency price movements and patterns using various ML techniques.',
    longDescription: `Comprehensive analysis of Bitcoin and major altcoin relationships, investigating price trends, 
                     volatility patterns, and predictive modeling using advanced statistical and machine learning techniques. 
                     The project combines time series analysis with machine learning approaches to understand cryptocurrency 
                     market dynamics and price predictions.`,
    tags: ['R', 'Generalized Models', 'Time Series Analysis'],
    image: btcProject,
    pdf: btcProjectPdf,
    github: 'https://github.com/devyn-miller/btc-altcoin-analysis',
    technologies: {
      'Core': ['R', 'Generalized Models'],
      'Analysis': ['Time Series Analysis', 'Statistical Modeling'],
      'Data Processing': ['Data Cleaning'],
      'Visualization': ['ggplot2']
    },
    categories: ['Machine Learning', 'Data Science'],
    challenges: [
      'Processing large-scale cryptocurrency data',
      'Implementing complex time series models',
      'Handling market volatility in predictions',
      'Managing data quality and consistency'
    ],
    solutions: [
      'Developed robust data processing pipeline',
      'Implemented advanced time series models',
      'Created comprehensive visualization system',
      'Applied statistical validation techniques'
    ],
    slug: 'cryptocurrency-price-analysis'
  },
  {
    id: 16,
    title: 'GitHub Repository Analysis Tool',
    description: 'Advanced GitHub repository analysis tool with sentiment analysis and metrics tracking',
    longDescription: `A sophisticated GitHub Repository Analysis Tool built with modern web technologies, featuring 
                      sentiment analysis, metrics tracking, and advanced search capabilities. The application provides 
                      comprehensive repository insights through interactive visualizations and detailed analytics.`,
    tags: ['React', 'TypeScript', 'Node.js', 'Express', 'TanStack Query', 'Zustand'],
    image: ghRepoFinderImg,
    github: 'https://github.com/devyn-miller/gh-repo-proj-dec5-2024',
    demo: 'https://devyn-miller.github.io/gh-repo-proj-dec5-2024/',
    technologies: {
      'Frontend': ['React 18', 'TypeScript', 'TailwindCSS', 'TanStack Query', 'Zustand'],
      'Backend': ['Express.js', 'Node.js', 'REST API'],
      'Data Management': ['Dexie.js', 'Axios', 'React Query'],
      'Testing': ['Vitest', 'React Testing Library', 'Jest DOM']
    },
    categories: ['Web Development', 'Data Science'],
    challenges: [
      'Implementing complex sentiment analysis system',
      'Managing large-scale repository data',
      'Optimizing performance with real-time updates',
      'Building accessible and responsive UI'
    ],
    solutions: [
      'Developed weighted scoring system for sentiment analysis',
      'Implemented efficient caching and data management',
      'Used React Query for optimized data fetching',
      'Created comprehensive accessibility features'
    ],
    slug: 'github-repository-analysis'
  },
];

const hypertrophyProject = projects.find(p => p.title === 'HyperGain - Hypertrophy Web App');
if (hypertrophyProject) {
  hypertrophyProject.videoLink = 'https://drive.google.com/file/d/1ZWWGhfbeOaQ67FwmJ4q_L-lQUILUwTM3/view';
}

const completedProjects = projects.filter(p => 
  p.title !== 'HyperGain - Hypertrophy Web App' && 
  p.title !== 'Stress Management iOS/WatchOS App'
);

const pausedProjects = projects.filter(p => 
  p.title === 'HyperGain - Hypertrophy Web App' || 
  p.title === 'Stress Management iOS/WatchOS App'
);

const ProjectCard = ({ project, onClick }) => {
  const [showVersionSelector, setShowVersionSelector] = useState(false);
  const [selectorType, setSelectorType] = useState('');

  const handleLinkClick = (e, type) => {
    e.stopPropagation();
    if (project.projects && project.projects.length > 1) {
      setSelectorType(type);
      setShowVersionSelector(true);
    }
  };

  // Create a URL-friendly slug from the project title
  const projectSlug = project.slug || project.id || project.title.toLowerCase().replace(/\s+/g, '-').replace(/[^a-z0-9-]/g, '');
  
  return (
    <motion.div
      className="group relative rounded-lg overflow-hidden bg-white dark:bg-gray-800 shadow-lg hover:shadow-xl transition-shadow cursor-pointer"
      onClick={(e) => {
        e.stopPropagation();
        onClick();
        
        // Update URL with project ID using query parameter
        if (window.history && window.history.pushState) {
          const newUrl = `${window.location.pathname}?project=${projectSlug}`;
          window.history.pushState({ path: newUrl }, '', newUrl);
        }
      }}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
    >
      <div className="relative h-48 overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent z-10" />
        {project.images ? (
          <div className="flex justify-center items-center h-full">
            {project.images.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={project.title}
                className="w-1/2 h-full object-cover"
              />
            ))}
          </div>
        ) : (
          <img
            src={project.image}
            alt={project.title}
            className={`w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-300 ${
              project.title === 'Naughty or Nice AI Chatbot' ? 'object-top' : 'object-center'
            }`}
          />
        )}
        <div className="absolute bottom-0 left-0 right-0 p-4 z-20">
          <h3 className="text-lg font-display font-bold text-white mb-1">{project.title}</h3>
          <p className="text-sm text-gray-200 line-clamp-2">{project.description}</p>
        </div>
      </div>
      
      <div className="p-4">
        <div className="flex flex-wrap gap-2 mb-4">
          {project.tags.map((tag, index) => (
            <span
              key={index}
              className="px-2 py-1 text-xs rounded-full bg-blue-100 dark:bg-blue-900 text-blue-700 dark:text-blue-300"
            >
              {tag}
            </span>
          ))}
        </div>

        <div className="flex justify-between items-center">
          <button
            onClick={(e) => {
              e.stopPropagation();
              onClick();
            }}
            className="text-blue-600 dark:text-blue-400 hover:text-blue-700 dark:hover:text-blue-300 font-medium"
          >
            Learn More
          </button>
          <div className="flex space-x-2">
            {project.github && (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  if (project.projects && project.projects.length > 1) {
                    setSelectorType('github');
                    setShowVersionSelector(true);
                  } else {
                    window.open(project.github, '_blank');
                  }
                }}
                className="text-gray-600 dark:text-gray-400 hover:text-gray-800 dark:hover:text-gray-200"
                title="GitHub Repository"
              >
                <FaGithub className="w-5 h-5" />
              </button>
            )}
            {project.demo && (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  if (project.projects && project.projects.length > 1) {
                    setSelectorType('demo');
                    setShowVersionSelector(true);
                  } else {
                    window.open(project.demo, '_blank');
                  }
                }}
                className="text-gray-600 dark:text-gray-400 hover:text-gray-800 dark:hover:text-gray-200"
                title="Live Demo"
              >
                <FaExternalLinkAlt className="w-4 h-4" />
              </button>
            )}
            {project.pdf && (
              <a
                href={project.pdf}
                target="_blank"
                rel="noopener noreferrer"
                onClick={(e) => e.stopPropagation()}
                className="text-gray-600 dark:text-gray-400 hover:text-gray-800 dark:hover:text-gray-200"
                title="View PDF Report"
              >
                <FaFilePdf className="w-5 h-5" />
              </a>
            )}
            {project.rCode && (
              <a
                href={project.rCode}
                target="_blank"
                rel="noopener noreferrer"
                onClick={(e) => e.stopPropagation()}
                className="text-gray-600 dark:text-gray-400 hover:text-gray-800 dark:hover:text-gray-200"
                title="View R Code"
              >
                <FaCode className="w-5 h-5" />
              </a>
            )}
            {project.videoLink && (
              <a
                href={project.videoLink}
                target="_blank"
                rel="noopener noreferrer"
                onClick={(e) => e.stopPropagation()}
                className="text-gray-600 dark:text-gray-400 hover:text-gray-800 dark:hover:text-gray-200"
                title="Watch Demo Video"
              >
                <FaPlayCircle className="w-5 h-5" />
              </a>
            )}
          </div>
        </div>
      </div>

      <VersionSelector
        isOpen={showVersionSelector}
        onClose={() => setShowVersionSelector(false)}
        type={selectorType}
        project={project}
      />
    </motion.div>
  );
};

const VersionSelector = ({ isOpen, onClose, type, project }) => {
  if (!isOpen) return null;

  const handleVersionClick = (url) => {
    window.open(url, '_blank');
    onClose();
  };

  return (
    <motion.div
      className="fixed inset-0 z-50 flex items-center justify-center"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div 
        className="absolute inset-0 bg-black/50 backdrop-blur-sm" 
        onClick={onClose} 
      />
      <motion.div
        className="relative bg-white dark:bg-gray-800 p-8 rounded-2xl shadow-xl max-w-md w-full mx-4"
        initial={{ scale: 0.9, y: 20 }}
        animate={{ scale: 1, y: 0 }}
        exit={{ scale: 0.9, y: 20 }}
      >
        <div className="absolute top-4 right-4">
          <button
            onClick={onClose}
            className="p-2 rounded-full hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors"
            aria-label="Close"
          >
            <FaTimes className="text-gray-500 dark:text-gray-400" />
          </button>
        </div>

        <h3 className="text-2xl font-display font-bold text-gray-900 dark:text-white mb-2">
          Select Version
        </h3>
        <p className="text-gray-600 dark:text-gray-400 mb-6">
          Choose which version of 2048 you'd like to {type === 'github' ? 'view on GitHub' : 'play'}
        </p>

        <div className="space-y-4">
          {project.projects.map((subProject, index) => (
            <button
              key={index}
              onClick={() => handleVersionClick(type === 'github' ? subProject.github : subProject.demo)}
              className="group w-full text-left"
            >
              <div className="p-4 rounded-xl border-2 border-gray-200 dark:border-gray-700 hover:border-blue-500 dark:hover:border-blue-400 transition-colors">
                <div className="flex items-center justify-between">
                  <div>
                    <h4 className="font-semibold text-gray-900 dark:text-white group-hover:text-blue-500 dark:group-hover:text-blue-400 transition-colors">
                      {subProject.title}
                    </h4>
                    <p className="text-sm text-gray-600 dark:text-gray-400 mt-1">
                      {subProject.description}
                    </p>
                  </div>
                  <div className="ml-4">
                    {type === 'github' ? (
                      <FaGithub className="text-gray-400 group-hover:text-blue-500 dark:group-hover:text-blue-400 w-6 h-6 transition-colors" />
                    ) : (
                      <FaExternalLinkAlt className="text-gray-400 group-hover:text-blue-500 dark:group-hover:text-blue-400 w-5 h-5 transition-colors" />
                    )}
                  </div>
                </div>
              </div>
            </button>
          ))}
        </div>
      </motion.div>
    </motion.div>
  );
};

const ProjectModal = ({ project, onClose }) => {
  if (!project) return null;

  const [showVersionSelector, setShowVersionSelector] = useState(false);
  const [type, setType] = useState('');

  return (
    <Dialog
      as="div"
      className="fixed inset-0 z-50 overflow-y-auto"
      open={true}
      onClose={onClose}
    >
      <div className="flex items-center justify-center min-h-screen px-4">
        <Dialog.Overlay className="fixed inset-0 bg-black/50" />
        
        <motion.div
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.9 }}
          className="relative bg-white dark:bg-gray-800 rounded-xl max-w-4xl w-full mx-auto shadow-2xl"
        >
          <button
            onClick={onClose}
            className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200 z-[60]"
          >
            <FaTimes className="w-6 h-6" />
          </button>

          <div className="p-6 md:p-8">
            <div className="relative h-64 md:h-96 rounded-lg overflow-hidden mb-6 z-50">
              {project.images ? (
                <div className="flex justify-center items-center h-full">
                  {project.images.map((image, index) => (
                    <img
                      key={index}
                      src={image}
                      alt={project.title}
                      className="w-1/2 h-full object-cover"
                    />
                  ))}
                </div>
              ) : (
                <img
                  src={project.image}
                  alt={project.title}
                  className={`w-full h-64 md:h-96 object-cover rounded-lg shadow-lg ${
                    project.title === 'Naughty or Nice AI Chatbot' ? 'object-top' : 'object-center'
                  }`}
                />
              )}
            </div>

            <h2 className="text-3xl font-bold text-gray-900 dark:text-white mb-4">
              {project.title}
            </h2>

            <p className="text-gray-600 dark:text-gray-400 mb-6">
              {project.longDescription}
            </p>

            {project.projects && (
              <div className="grid md:grid-cols-2 gap-8 mb-6">
                {project.projects.map((subProject, index) => (
                  <div key={index}>
                    <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-3">
                      {subProject.title}
                    </h3>
                    <p className="text-gray-600 dark:text-gray-400 mb-3">
                      {subProject.description}
                    </p>
                    <div className="grid grid-cols-2 gap-4">
                      {Object.entries(subProject.technologies || {}).map(([category, techs]) => (
                        <div key={category}>
                          <h4 className="font-medium text-gray-700 dark:text-gray-300 mb-2">
                            {category}
                          </h4>
                          <ul className="text-gray-600 dark:text-gray-400 space-y-1">
                            {techs.map((tech, index) => (
                              <li key={index}>{tech}</li>
                            ))}
                          </ul>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            )}

            <div className="mb-6">
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-3">
                Technologies Used
              </h3>
              <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                {Object.entries(project.technologies || {}).map(([category, techs]) => (
                  <div key={category}>
                    <h4 className="font-medium text-gray-700 dark:text-gray-300 mb-2">
                      {category}
                    </h4>
                    <ul className="text-gray-600 dark:text-gray-400 space-y-1">
                      {techs.map((tech, index) => (
                        <li key={index}>{tech}</li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            </div>

            <div className="flex flex-wrap gap-4">
              {project.github && (
                <button
                  onClick={() => {
                    if (project.projects) {
                      setType('github');
                      setShowVersionSelector(true);
                    } else {
                      window.open(project.github, '_blank');
                    }
                  }}
                  className="flex items-center gap-2 px-4 py-2 bg-gray-800 text-white rounded-lg hover:bg-gray-700 transition-colors"
                >
                  <FaGithub />
                  GitHub
                </button>
              )}
              {project.pdf && (
                <a
                  href={project.pdf}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
                >
                  <FaFilePdf />
                  PDF Report
                </a>
              )}
              {project.rCode && (
                <a
                  href={project.rCode}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center gap-2 px-4 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition-colors"
                >
                  <FaFileCode />
                  R Code
                </a>
              )}
              {project.demo && (
                <button
                  onClick={() => {
                    if (project.projects) {
                      setType('demo');
                      setShowVersionSelector(true);
                    } else {
                      window.open(project.demo, '_blank');
                    }
                  }}
                  className="flex items-center gap-2 px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-colors"
                >
                  <FaExternalLinkAlt />
                  Live Demo
                </button>
              )}
              {project.videoLink && (
                <a
                  href={project.videoLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center gap-2 px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 transition-colors"
                >
                  <FaPlayCircle />
                  Watch Video
                </a>
              )}
            </div>

            <VersionSelector
              isOpen={showVersionSelector}
              onClose={() => setShowVersionSelector(false)}
              type={type}
              project={project}
            />
          </div>
        </motion.div>
      </div>
    </Dialog>
  );
};

const categories = ['Featured', 'All', 'Machine Learning', 'Data Science', 'Web Development', 'Games', 'Archives'];

const featuredProjectTitles = [
  'Marriage Rates Using Logistic Regression',
  'Video Colorization Using Keras Tuner',
  'Bayesian vs Frequentist Analysis',
  '2048 Game Variations',
  'Customer Inertia Analysis',
  'Cryptocurrency Price Analysis',
  'GitHub Repository Analysis Tool'
];

const Projects = () => {
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('Featured');
  const containerRef = useRef(null);
  const isInView = useInView(containerRef, { once: true, margin: "-20% 0px -20% 0px" });

  // Check for project ID in URL on component mount
  useEffect(() => {
    const checkUrlForProject = () => {
      const hash = window.location.hash;
      const urlParams = new URLSearchParams(window.location.search);
      const projectParam = urlParams.get('project');
      
      // First check for query parameter format
      if (projectParam) {
        const project = projects.find(p => {
          const slug = p.slug || p.id || p.title.toLowerCase().replace(/\s+/g, '-').replace(/[^a-z0-9-]/g, '');
          return slug === projectParam;
        });
        
        if (project) {
          setSelectedProject(project);
          return;
        }
      }
      
      // Backward compatibility - check for hash format
      if (hash && (hash.includes('#/#projects/') || hash.includes('#projects/'))) {
        let projectSlug;
        if (hash.includes('#/#projects/')) {
          projectSlug = hash.substring(hash.indexOf('#/#projects/') + 12);
        } else {
          projectSlug = hash.substring(hash.indexOf('#projects/') + 10);
        }
        
        const project = projects.find(p => {
          const slug = p.slug || p.id || p.title.toLowerCase().replace(/\s+/g, '-').replace(/[^a-z0-9-]/g, '');
          return slug === projectSlug;
        });
        
        if (project) {
          setSelectedProject(project);
          
          // Convert hash format to query parameter format
          if (window.history && window.history.pushState) {
            const newUrl = `${window.location.pathname}?project=${projectSlug}`;
            window.history.pushState({ path: newUrl }, '', newUrl);
          }
        }
      }
    };
    
    checkUrlForProject();
    
    // Listen for URL changes
    window.addEventListener('popstate', checkUrlForProject);
    return () => window.removeEventListener('popstate', checkUrlForProject);
  }, []);

  // Add event listener for opening projects from Skills section
  useEffect(() => {
    const handleOpenProject = (event) => {
      const projectId = event.detail;
      const project = projects.find(p => p.id === projectId);
      if (project) {
        setSelectedProject(project);
        
        // Update URL with project ID using query parameter
        if (window.history && window.history.pushState) {
          const projectSlug = project.slug || project.id || project.title.toLowerCase().replace(/\s+/g, '-').replace(/[^a-z0-9-]/g, '');
          const newUrl = `${window.location.pathname}?project=${projectSlug}`;
          window.history.pushState({ path: newUrl }, '', newUrl);
        }
      }
    };

    window.addEventListener('openProject', handleOpenProject);
    return () => window.removeEventListener('openProject', handleOpenProject);
  }, []);

  const filteredCompletedProjects = completedProjects.filter(project => {
    if (selectedCategory === 'Featured') {
      return featuredProjectTitles.includes(project.title);
    }
    if (selectedCategory === 'Archives') {
      return false; // Currently no archived projects
    }
    return selectedCategory === 'All' || (project.categories && project.categories.includes(selectedCategory));
  });

  const filteredPausedProjects = pausedProjects.filter(project => 
    selectedCategory === 'All' || (project.categories && project.categories.includes(selectedCategory))
  );

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.5,
        when: "beforeChildren",
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { 
      opacity: 0,
      y: 20
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: [0.22, 1, 0.36, 1]
      }
    }
  };

  // Add console.log to debug
  console.log('Selected Category:', selectedCategory);
  console.log('Filtered Projects:', filteredCompletedProjects);
  console.log('All Projects:', projects);

  return (
    <ScrollAnimation>
      <section id="projects" className="py-20 bg-gradient-to-b from-white to-sky-50 dark:from-gray-900 dark:to-gray-800 transition-colors duration-300">
        <div className="container mx-auto px-6">
          <motion.div
            ref={containerRef}
            initial="hidden"
            animate={isInView ? "visible" : "hidden"}
            variants={containerVariants}
          >
            {/* Main Header */}
            <motion.div 
              variants={itemVariants}
              className="text-center mb-12"
            >
              <h2 className="text-4xl font-display font-bold mb-4 bg-gradient-to-r from-primary-600 to-accent-500 dark:from-primary-400 dark:to-accent-400 text-transparent bg-clip-text">
                Recent Projects
              </h2>
              <p className="text-gray-600 dark:text-gray-300 max-w-2xl mx-auto">
                Explore my portfolio of data science and development projects, showcasing expertise in machine learning, statistics, and software development.
                <span className="block mt-2 text-sm italic">
                  All projects featured here were developed in 2024.
                </span>
              </p>
            </motion.div>

            {/* Categories */}
            <motion.div 
              variants={itemVariants}
              className="flex flex-wrap justify-center gap-4 mb-12"
            >
              {categories.map((category) => (
                <button
                  key={category}
                  onClick={() => setSelectedCategory(category)}
                  className={`px-4 py-2 rounded-full text-sm font-medium transition-all duration-300 ${
                    selectedCategory === category
                      ? 'bg-primary-500 text-white shadow-soft'
                      : 'bg-white dark:bg-gray-800 text-gray-600 dark:text-gray-300 hover:bg-primary-50 dark:hover:bg-gray-700'
                  }`}
                >
                  {category}
                </button>
              ))}
            </motion.div>

            {/* Show message when Archives is selected */}
            {selectedCategory === 'Archives' && (
              <motion.div
                variants={itemVariants}
                className="text-center py-12"
              >
                <p className="text-gray-600 dark:text-gray-300">
                  No archived projects available at this time.
                </p>
              </motion.div>
            )}

            {/* Completed Projects Section */}
            {selectedCategory !== 'Archives' && (
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-20">
                {filteredCompletedProjects.map((project) => (
                  <ProjectCard
                    key={project.id}
                    project={project}
                    onClick={() => setSelectedProject(project)}
                  />
                ))}
              </div>
            )}

            {/* Show Experimental Projects section only if there are matching projects */}
            {filteredPausedProjects.length > 0 && (
              <>
                <motion.div 
                  variants={itemVariants}
                  className="text-center mb-12"
                >
                  <h2 className="text-3xl font-display font-bold mb-4 bg-gradient-to-r from-gray-600 to-gray-500 dark:from-gray-400 dark:to-gray-300 text-transparent bg-clip-text">
                    Experimental Projects
                  </h2>
                  <p className="text-gray-600 dark:text-gray-300 max-w-2xl mx-auto">
                    A collection of innovative projects currently on pause - experiments in new technologies and concepts that sparked interesting insights.
                  </p>
                </motion.div>

                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                  {filteredPausedProjects.map((project) => (
                    <ProjectCard
                      key={project.id}
                      project={project}
                      onClick={() => setSelectedProject(project)}
                    />
                  ))}
                </div>
              </>
            )}
          </motion.div>
        </div>
      </section>

      {/* Project Modal */}
      <AnimatePresence>
        {selectedProject && (
          <ProjectModal
            project={selectedProject}
            onClose={() => {
              setSelectedProject(null);
              
              // Reset URL when modal is closed
              if (window.history && window.history.pushState) {
                const newUrl = window.location.pathname;
                window.history.pushState({ path: newUrl }, '', newUrl);
              }
            }}
          />
        )}
      </AnimatePresence>
    </ScrollAnimation>
  );
};

export default Projects;
