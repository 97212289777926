import { useState, useRef } from 'react';
import { motion, useInView } from 'framer-motion';
import ScrollAnimation from './ScrollAnimation';
import { FaFileAlt, FaExternalLinkAlt } from 'react-icons/fa';
import writingOne from '../assets/writing-samples/ENGR_520.pdf';
import writingTwo from '../assets/writing-samples/Ethical_Risk_Assessment.pdf';
import writingThree from '../assets/writing-samples/game-theory.pdf';
import writingFour from '../assets/writing-samples/ml-applications-in-contests.pdf';
import writingFive from '../assets/writing-samples/facial-recognition.pdf';
const TechnicalWriting = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true, margin: "-100px" });
  
  const [hoveredItem, setHoveredItem] = useState(null);

  const writingSamples = [
    {
      id: 1,
      title: "NSF Technical Proposal",
      description: "Technical framework for explainable AI in scientific applications.",
      link: writingOne,
      icon: <FaFileAlt className="w-5 h-5" />,
      date: "Spring 2025"
    },
    {
      id: 2,
      title: "Ethical Risk Assessment",
      description: "Assessment of ethical risks associated with AI-driven tools, cloud-based development, and humanoid robotics.",
      link: writingTwo,
      icon: <FaFileAlt className="w-5 h-5" />,
      date: "Spring 2025"
    },
    {
      id: 3,
      title: "Machine Learning Applications in Contests",
      description: "Analysis of machine learning as it applies to contests in game theory.",
      link: writingFour,
      icon: <FaFileAlt className="w-5 h-5" />,
      date: "Spring 2024"
    },
    {
      id: 4,
      title: "Facial Recognition and Emotion Detection Technologies",
      description: "Emergence of facial recognition technology, powered by artificial intelligence algorithms.",
      link: writingFive,
      icon: <FaFileAlt className="w-5 h-5" />,
      date: "Spring 2024"
    },
    {
      id: 5,
      title: "Game Theory's Role in Darwinian Evolution",
      description: "Application of game theory in Darwinian evolution.",
      link: writingThree,
      icon: <FaFileAlt className="w-5 h-5" />,
      date: "Fall 2023"
    },
  ];

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.3
      }
    }
  };

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 }
  };

  return (
    <section id="writing" className="py-20 bg-gradient-to-b from-white to-sky-50 dark:from-gray-900 dark:to-gray-800 transition-colors duration-300">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <ScrollAnimation>
          <h2 className="text-3xl font-bold text-center text-gray-900 dark:text-white mb-4">
            Writing
          </h2>
          <p className="text-lg text-gray-600 dark:text-gray-300 text-center mb-12">
            Recent samples of my technical writing.
          </p>
        </ScrollAnimation>

        <div ref={ref}>
          {isInView && (
            <motion.ul
              variants={container}
              initial="hidden"
              animate="show"
              className="space-y-6 mt-8"
            >
              {writingSamples.map((sample) => (
                <motion.li
                  key={sample.id}
                  variants={item}
                  className="bg-gray-50 dark:bg-gray-800 rounded-lg shadow-md p-6 transition-all duration-300"
                  onMouseEnter={() => setHoveredItem(sample.id)}
                  onMouseLeave={() => setHoveredItem(null)}
                  whileHover={{ scale: 1.02 }}
                >
                  <div className="flex items-start space-x-4">
                    <div className="bg-primary-100 dark:bg-primary-900 p-3 rounded-lg">
                      {sample.icon}
                    </div>
                    <div className="flex-1">
                      <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-2 flex items-center">
                        {sample.title}
                        <a 
                          href={sample.link} 
                          target="_blank" 
                          rel="noopener noreferrer"
                          className="inline-flex items-center ml-2 text-primary-500 hover:text-primary-600"
                        >
                          <FaExternalLinkAlt className="w-4 h-4" />
                        </a>
                      </h3>
                      <p className="text-gray-600 dark:text-gray-300">
                        {sample.description}
                      </p>
                      <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
                        {sample.date}
                      </p>
                    </div>
                  </div>
                </motion.li>
              ))}
            </motion.ul>
          )}
        </div>
      </div>
    </section>
  );
};

export default TechnicalWriting; 