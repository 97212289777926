import mirapic from '../assets/mira.png';

export const blogCategories = [
  {
    id: 'personal-development',
    name: 'Personal Development',
    description: 'Stories of personal growth, learning, and self-improvement'
  },
  {
    id: 'web-development',
    name: 'Web Development',
    description: 'Frontend and backend development, best practices, and experiences'
  },
  {
    id: 'academic-achievement',
    name: 'Academic Achievement',
    description: 'Academic milestones, research, and educational experiences'
  }
];

export const blogPosts = [
  {
    id: 'masters-graduation-2024',
    title: "Graduating with M.S. in Behavioral and Computational Economics: A Journey Through Experimental Economics",
    date: "2024-12-15",
    slug: 'masters-graduation-2024',
    preview: "Reflecting on my journey through Chapman University's innovative M.S. in Behavioral and Computational Economics program, combining experimental economics with cutting-edge computational methods.",
    content: `# Graduating with M.S. in Behavioral and Computational Economics: A Journey Through Experimental Economics

As I complete my Master of Science in Behavioral and Computational Economics (M.S. BCE) at Chapman University's Argyros School of Business and Economics this December 2024, I find myself reflecting on an extraordinary academic journey that has bridged the worlds of behavioral economics, computational methods, and experimental research.

## The Economic Science Institute Legacy

I have truly enjoyed being part of Chapman's Economic Science Institute, inspired and developed by Vernon L. Smith. Smith's innovative approach to economics education earned him the 2002 Nobel Prize in Economic Sciences and has shaped my understanding of economic principles through hands-on experimentation and research.

## Academic Journey Highlights

Throughout my master's program, I've had the privilege of exploring a range of subjects that combine traditional economic theory with cutting-edge computational methods:

### Core Economic Theory and Behavior
- **Behavioral Economics & Finance**: Explored the psychological underpinnings of economic decision-making
- **Game Theory I & II**: Mastered the mathematical foundations of strategic interaction
- **Economic Systems Design**: Studied mechanism design and market systems
- **Auction and Market Design**: Analyzed efficiency and fairness in market mechanisms
- **Bayesian Statistics**: Applied probabilistic reasoning to economic problems

### Computational and Technical Skills
- **Computational Economics I & II**: Developed advanced modeling techniques
- **Deep Learning & Computer Vision**: Applied AI to economic analysis
- **Database System Implementation**: Built robust data management systems
- **Statistical Machine Learning**: Mastered predictive modeling techniques

### Research and Professional Development
- **International Research Incubator**: Engaged in cross-cultural research projects
- **Individual Study**: Conducted independent research under faculty guidance

## Research and Practical Applications

As a Graduate Research Assistant at the Economic Science Institute, I've had the opportunity to:
- Participate in experimental economics research
- Apply computational methods to analyze market behaviors
- Collaborate with distinguished faculty members
- Contribute to ongoing research in behavioral economics

## The ESI Approach to Learning

In my final term as a Business Economics undergraduate student at UC San Diego, I began looking into grad school. Through much research comparing various graduate programs, I learned how most of them focus solely on the abstract theories of economics. However, Chapman really appealed to me because of how it integrates experimental economics into the learning experience. Campus is also only 15 minutes from my house! I am so glad that I chose to go with Chapman's M.S. BCE program. As an ESI student, I've been a part of conducting hands-on experiments in real-world settings. This approach allows us to:
- Observe economic principles in action
- Test theoretical predictions against actual behavior
- Develop intuitive understanding beyond formal theory
- Question and analyze unexpected outcomes

## Looking Forward

Earning this degree has been more than just academic achievement. Through my time in the program, I've grown my passion and curiosity for applying experimental and computational methods to real-world challenges. I have been immersed in a special world of economics, where behavioral insights, computational tools, and experimental methods work together to provide a unique perspective on economic problem-solving.

I look forward to applying what I've learned in this program in future endeavors, continuing the legacy of innovative economic research that Chapman University and the Economic Science Institute represent!

— Devyn`,
    author: 'Devyn Miller',
    categories: ['academic-achievement', 'personal-development'],
    featured: true,
    readTime: '8 min read',
    tags: ['Economics', 'Education', 'Research', 'Data Science'],
    image: '/images/blog/graduation-2024.jpg'
  },
  {
    id: 'crafting-my-digital-identity',
    title: "Crafting My Digital Identity: Building My Personal Portfolio Website",
    date: "2024-11-21",
    slug: 'crafting-my-digital-identity',
    preview: "A deep dive into the creation of my personal portfolio website, the technologies behind it, and the personal growth that came with building my digital home.",
    content: `# Crafting My Digital Identity

Every digital journey starts with a spark of inspiration! 🌟 For me, this portfolio website has been a wonderful opportunity for me to combine my technical skills, professional identity, and personal experiences into a unified platform. Given the rapidly changing world with a myriad of new technological innovations released every day, I aim to use my portfolio website as more than just a resume, but as a functional and creative space to showcase skills, share projects, and communicate who I am as a developer and creator.

## Technical Foundation

To bring this vision to life, I selected a tech stack that balanced performance with usability. I used React and Vite for a fast development process, and Tailwind CSS allowed me to create a clean, modern design. To add a layer of interactivity, I integrated Framer Motion, which helped make animations fluid and engaging.

## Design Philosophy

For this portfolio, I chose to focus on three key principles: simplicity, responsiveness, and personality. I wanted to have a clean layout so that my content could take center stage, as well as allow my website to be compatible with a variety of devices. I added subtle animations and small personal touches to express a little personality without overwhelming the professionalism of the site.

## Learning and Growth

I learned so much while building this portfolio! My intial draft of this website used HTML, CSS, and JavaScript exclusively; however, I decided to recreate the site using tools that would enable me to have a more customized portfolio so I could more easily add finer details, such as subtle animations. Making this site required me to problem-solve and refine with multiple iterations, from optimizing performance-heavy animations to creating an intuitive user interface to align with modern design standards.

## Beyond the Code

Outside of coding, I LOVE music—specifically pop, singer-songwriter, and musical sountracks. 🎶 Also, I enjoy singing and use it as my creative outlet. Some of my favorite artists are Sabrina Carpenter, Gracie Abrams, and Jenna Raine. Staying active through fitness helps me maintain focus and discipline through my academic and professional pursuits.

![Mira and me](${mirapic})

Spending time with my dog Mira is therapy for me; she helps bring out my inner child, full of spontaneity and joy!

## Looking Forward

This portfolio is just the beginning. I hope this site evolves with me, and I plan to use it to continue sharing insights, document my projects, and connecting with others in the tech community!

## A Personal Note

Thank you for taking the time to explore my digital space! Whether you're a potential collaborator, employer, or just browsing, I hope you find this a clear and engaging representation of my work and interests.

— Devyn`,
    date: '2024-11-21',
    author: 'Devyn Miller',
    categories: ['personal-development', 'web-development'],
    featured: true,
    readTime: '4 min read',
    tags: ['Web Development', 'React', 'Personal Branding', 'Design'],
    image: mirapic
  }
];

export const getPostsByCategory = (category) => {
  if (!category) {
    return blogPosts;  // Return all posts when no category is selected
  }
  return blogPosts.filter(post => post.categories.includes(category));
};

export const getRelatedPosts = (currentPost) => {
  if (!currentPost) return [];
  
  return blogPosts
    .filter(post => post.id !== currentPost.id)
    .filter(post => 
      post.categories.some(category => 
        currentPost.categories.includes(category)
      )
    )
    .slice(0, 3);
};

export const searchPosts = (query) => {
  const searchTerm = query.toLowerCase();
  return blogPosts.filter(post => 
    post.title.toLowerCase().includes(searchTerm) ||
    post.preview.toLowerCase().includes(searchTerm) ||
    post.content.toLowerCase().includes(searchTerm)
  );
};
