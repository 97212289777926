import btcProject from '../assets/projects/btc-final-project.png'
import btcProjectPdf from '../assets/projects/CPSC 540 Final Project - Cryptocurrency Price Analysis.pdf'
import ghRepoFinderImg from '../assets/projects/gh-repo-finder.png'

export const projects = [
  {
    id: 1,
    title: "Cryptocurrency Price Analysis",
    description: "Final project for CPSC 540 (Machine Learning) analyzing cryptocurrency price movements and patterns using various ML techniques including time series analysis and predictive modeling.",
    image: btcProject,
    pdf: btcProjectPdf,
    tech: ['Python', 'Scikit-learn', 'Pandas', 'NumPy'],
    // Add either a demo link if you have one, or remove the demo property
    // demo: "",
    report: btcProjectPdf
  },
  {
    id: 2,
    title: "Bayesian Time Series Forecasting",
    description: `Developed a sophisticated Bayesian forecasting model for time series data, 
                  incorporating uncertainty quantification and handling missing data.`,
    image: "/projects/forecasting.jpg",
    tags: ["Python", "PyMC3", "Time Series", "Bayesian Statistics"],
    links: {
      github: "https://github.com/yourusername/project1",
      demo: "https://demo-link-1.com",
    },
    highlights: [
      "Implemented hierarchical Bayesian models",
      "Achieved 25% improvement in forecast accuracy",
      "Handled complex seasonal patterns",
    ],
  },
  {
    id: 3,
    title: "Machine Learning Pipeline Optimization",
    description: `Built an automated ML pipeline optimization system using Bayesian optimization
                  to tune hyperparameters and select models.`,
    image: "/projects/ml-pipeline.jpg",
    tags: ["Python", "Scikit-learn", "MLflow", "Optimization"],
    links: {
      github: "https://github.com/yourusername/project2",
      demo: "https://demo-link-2.com",
    },
    highlights: [
      "Reduced model training time by 40%",
      "Implemented automated feature selection",
      "Developed custom optimization algorithms",
    ],
  },
  {
    id: 4,
    title: "Cryptocurrency Dashboard",
    description: `A real-time cryptocurrency tracking dashboard providing comprehensive market analysis 
                  and visualization of cryptocurrency data using the CryptoCompare API. Features interactive 
                  charts, technical analysis tools, and real-time market insights.`,
    image: "/assets/projects/crypto-dash.png",
    tags: ["Python", "Streamlit", "API", "Plotly", "Pandas"],
    links: {
      github: "https://github.com/devyn-miller/crypto-dashboard",
      demo: "https://devyn-miller-crypto-dashboard-dashboard-mhno6d.streamlit.app/",
    },
    highlights: [
      "Real-time cryptocurrency market data tracking and analysis",
      "Interactive technical analysis with multiple indicators",
      "Comprehensive market overview with global statistics",
      "Advanced data visualization using Plotly and custom charts",
      "Efficient API integration with caching and error handling"
    ],
  },
  {
    id: 5,
    title: "Interactive Data Visualization Dashboard",
    description: `Created a real-time dashboard for visualizing complex statistical analyses
                  and machine learning model results.`,
    image: "/projects/dashboard.jpg",
    tags: ["R", "Shiny", "D3.js", "Dashboard"],
    links: {
      github: "https://github.com/yourusername/project3",
      demo: "https://demo-link-3.com",
    },
    highlights: [
      "Built interactive visualizations",
      "Implemented real-time updates",
      "Designed intuitive user interface",
    ],
  },
  {
    id: 6,
    title: "Statistical Analysis Package",
    description: `Developed a Python package for advanced statistical analysis,
                  focusing on Bayesian methods and uncertainty quantification.`,
    image: "/projects/stats-package.jpg",
    tags: ["Python", "Statistics", "Package Development"],
    links: {
      github: "https://github.com/yourusername/project4",
      demo: "https://demo-link-4.com",
    },
    highlights: [
      "Published to PyPI",
      "Comprehensive documentation",
      "Extensive test coverage",
    ],
  },
  {
    id: 7,
    title: "Resume",
    description: "Devyn Miller's professional resume.",
    filePath: "/projects/resume.pdf",
    isResume: true
  },
  {
    id: 8,
    title: "Cryptocurrency Price Analysis",
    description: `Comprehensive analysis of Bitcoin and major altcoin relationships, investigating price trends, 
                  volatility patterns, and predictive modeling using advanced statistical and machine learning techniques.`,
    image: "/projects/btc-final-project.png",
    tags: ["R", "Time Series", "Machine Learning", "API Integration", "Statistical Analysis"],
    links: {
      github: "https://github.com/devyn-miller/btc-altcoin-analysis",
      demo: "https://github.com/devyn-miller/btc-altcoin-analysis/raw/main/CPSC%20540%20Final%20Project%20-%20Cryptocurrency%20Price%20Analysis.pdf",
      code: "https://github.com/devyn-miller/btc-altcoin-analysis"
    },
    highlights: [
      "Developed ARIMA and SARIMAX models for cryptocurrency price prediction",
      "Achieved 84% reduction in RMSE through lagged Bitcoin price analysis",
      "Implemented comprehensive volatility analysis across major cryptocurrencies",
      "Integrated multiple data sources including CryptoCompare, Binance, and Kaggle",
      "Applied advanced statistical methods including GLM, GAM, and Bayesian analysis"
    ]
  },
  {
    id: 16,
    title: "GitHub Repository Analysis Tool",
    description: "Advanced GitHub repository analysis tool with sentiment analysis and metrics tracking",
    longDescription: `A sophisticated GitHub Repository Analysis Tool built with modern web technologies, featuring 
                      sentiment analysis, metrics tracking, and advanced search capabilities. The application provides 
                      comprehensive repository insights through interactive visualizations and detailed analytics.`,
    tags: ['React', 'TypeScript', 'Node.js', 'Express', 'TanStack Query', 'Zustand'],
    image: ghRepoFinderImg,
    github: 'https://github.com/devyn-miller/gh-repo-proj-dec5-2024',
    demo: 'https://devyn-miller.github.io/gh-repo-proj-dec5-2024/',
    technologies: {
      'Frontend': ['React 18', 'TypeScript', 'TailwindCSS', 'TanStack Query', 'Zustand'],
      'Backend': ['Express.js', 'Node.js', 'REST API'],
      'Data Management': ['Dexie.js', 'Axios', 'React Query'],
      'Testing': ['Vitest', 'React Testing Library', 'Jest DOM']
    },
    categories: ['Web Development', 'Data Science'],
    challenges: [
      'Implementing complex sentiment analysis system',
      'Managing large-scale repository data',
      'Optimizing performance with real-time updates',
      'Building accessible and responsive UI'
    ],
    solutions: [
      'Developed weighted scoring system for sentiment analysis',
      'Implemented efficient caching and data management',
      'Used React Query for optimized data fetching',
      'Created comprehensive accessibility features'
    ]
  }
];

const completedProjects = projects.filter(p => 
  p.title !== 'HyperGain - Hypertrophy Web App' && 
  p.title !== 'Stress Management iOS/WatchOS App' &&
  !p.isResume
);

const pausedProjects = projects.filter(p => 
  p.title === 'HyperGain - Hypertrophy Web App' || 
  p.title === 'Stress Management iOS/WatchOS App'
);

export { completedProjects, pausedProjects };
