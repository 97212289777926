import { motion } from 'framer-motion';
import { FaGithub, FaLinkedin, FaEnvelope } from 'react-icons/fa';
import Typewriter from 'typewriter-effect';
import profilePlaceholder from '../assets/profilepic.jpg';

const Hero = () => {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.8,
        staggerChildren: 0.3
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.6,
        ease: "easeOut"
      }
    }
  };

  // Animated background patterns
  const patternVariants = {
    animate: {
      scale: [1, 1.1, 1],
      rotate: [0, 5, -5, 0],
      transition: {
        duration: 20,
        repeat: Infinity,
        ease: "linear"
      }
    }
  };

  return (
    <section id="home" className="min-h-screen flex items-center justify-center py-20 relative overflow-hidden">
      {/* Animated background patterns */}
      <div className="absolute inset-0 bg-gradient-to-br from-primary-50 via-white to-sage-50 dark:from-gray-900 dark:via-gray-800 dark:to-gray-900">
        <motion.div
          className="absolute inset-0 opacity-[0.03] dark:opacity-[0.07]"
          initial={{ opacity: 0 }}
          animate={{ opacity: 0.03 }}
          transition={{ duration: 1 }}
        >
          {/* Code pattern background */}
          <div className="absolute inset-0" style={{ 
            backgroundImage: `url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23000000' fill-opacity='0.1'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
            backgroundSize: '60px 60px'
          }} />
        </motion.div>
        
        {/* Floating gradient orbs */}
        <motion.div
          variants={patternVariants}
          animate="animate"
          className="absolute top-1/4 -left-20 w-60 h-60 bg-primary-200/20 dark:bg-primary-500/10 rounded-full blur-3xl"
        />
        <motion.div
          variants={patternVariants}
          animate="animate"
          className="absolute bottom-1/4 -right-20 w-60 h-60 bg-accent-200/20 dark:bg-accent-500/10 rounded-full blur-3xl"
        />
      </div>

      <motion.div
        className="container mx-auto px-4 sm:px-6 flex flex-col md:flex-row items-center gap-8 md:gap-12 relative"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        {/* Profile Picture Section - Made more compact on mobile */}
        <motion.div 
          variants={itemVariants} 
          className="w-40 h-40 md:w-[300px] md:h-[300px] shrink-0 order-1 md:order-2 relative ml-4 md:ml-18 lg:ml-28 xl:ml-48"
        >
          {/* Glowing Frame with Further Reduced Intensity */}
          <div className="absolute inset-0 rounded-full border-2 border-transparent bg-gradient-to-r from-[#00BFFF] to-[#FF69B4] animate-pulse opacity-40 blur-xl scale-140 transition-transform duration-200 ease-in-out" />
          <div className="absolute inset-0 rounded-full border-2 border-transparent bg-gradient-to-r from-[#FF00FF] to-[#FFD700] animate-pulse opacity-40 blur-xl scale-140 transition-transform duration-200 ease-in-out" />
          <div className="absolute inset-0 rounded-full border-2 border-transparent bg-gradient-to-r from-[#00BFFF] to-[#FF1493] animate-pulse opacity-40 blur-xl scale-140 transition-transform duration-200 ease-in-out" />
          <div className="absolute inset-0 rounded-full border-2 border-transparent bg-gradient-to-r from-[#1E90FF] to-[#FF4500] animate-pulse opacity-40 blur-xl scale-140 transition-transform duration-200 ease-in-out" />
          
          {/* Inner Glow with Smaller Radius */}
          <div className="absolute inset-0 rounded-full border-0 bg-gradient-to-r from-[#00BFFF] to-[#FF69B4] animate-pulse opacity-80 blur-md" />
          
          {/* Outer Glow with Reduced Intensity for Dark Mode */}
          <div className="absolute inset-0 rounded-full border-0 bg-gradient-to-r from-[#FF6347] to-[#FF00FF] animate-pulse opacity-40 blur-2xl" />
          
          <motion.img
            src={profilePlaceholder}
            alt="Devyn Miller"
            className="relative rounded-full w-full h-full object-cover border-0 shadow-soft"
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            style={{ objectPosition: 'top 30%' }}
          />
        </motion.div>

        {/* Content Section - Improved spacing and alignment */}
        <motion.div 
          variants={itemVariants} 
          className="flex-1 order-2 md:order-1 flex flex-col items-center md:items-start max-w-2xl"
        >
          <motion.div 
            variants={itemVariants}
            className="inline-block mb-4 px-4 py-2 rounded-full bg-primary-100/80 dark:bg-primary-900/30 backdrop-blur-sm"
          >
            <span className="text-base sm:text-lg md:text-xl text-primary-600 dark:text-primary-300 font-medium tracking-tight">
              Machine Learning & Business Intelligence | Researcher
            </span>
          </motion.div>
          
          <h1 className="font-['Outfit'] text-4xl sm:text-5xl md:text-6xl font-semibold mb-4 bg-gradient-to-r from-primary-500 via-accent-400 to-purple-500 dark:from-primary-400 dark:via-accent-300 dark:to-purple-400 text-transparent bg-clip-text animate-gradient tracking-wide">
            <Typewriter
              options={{
                strings: ["Devyn Miller"],
                autoStart: true,
                delay: 50,
                cursor: "✦",
                loop: true,
                pauseFor: 60000,
                deleteSpeed: 30,
                wrapperClassName: "typewriter-text"
              }}
            />
          </h1>
          
          <p className="text-lg text-gray-600 dark:text-gray-300 mb-6 font-normal leading-relaxed tracking-tight text-center md:text-left">
          Hi! I'm an AI researcher, economist, and engineer with a passion for solving big, messy problems. I never set out to study both behavioral economics and machine learning, but curiosity has a way of taking you places you never expected. Whether it's optimizing resource allocation or running game-theoretic simulations, I believe that technology should enhance human intelligence, not replace it. I am always curious and always building!
          </p>

          <div className="flex flex-col sm:flex-row items-center gap-3 sm:gap-4 justify-center md:justify-start w-full sm:w-auto mb-6">
            <motion.a
              href="#experience"
              className="inline-flex items-center px-6 py-2.5 text-sm sm:text-base bg-primary-500/90 hover:bg-primary-500 
                       text-white rounded-lg transition-all duration-300 shadow-sm hover:shadow-md w-full sm:w-auto justify-center"
              variants={itemVariants}
            >
              View My Work
            </motion.a>
            <motion.a
              href="#contact"
              className="inline-flex items-center px-6 py-2.5 text-sm sm:text-base border border-primary-200 dark:border-primary-700 
                       text-primary-600 dark:text-primary-300 bg-white/50 dark:bg-gray-800/50 rounded-lg 
                       hover:bg-primary-50 dark:hover:bg-primary-900/30 transition-all duration-300
                       w-full sm:w-auto justify-center"
              variants={itemVariants}
            >
              Get in Touch
            </motion.a>
          </div>

          <motion.div 
            variants={itemVariants} 
            className="flex gap-6 justify-center md:justify-start w-full"
          >
            <a href="https://github.com/devyn-miller" 
               target="_blank" 
               rel="noopener noreferrer" 
               className="text-gray-600 hover:text-primary-500 dark:text-gray-400 dark:hover:text-primary-400 transition-colors">
              <FaGithub size={24} />
            </a>
            <a href="https://www.linkedin.com/in/devyn-c-miller/" 
               target="_blank" 
               rel="noopener noreferrer"
               className="text-gray-600 hover:text-primary-500 dark:text-gray-400 dark:hover:text-primary-400 transition-colors">
              <FaLinkedin size={24} />
            </a>
            <a href="mailto:devmiller@chapman.edu"
               className="text-gray-600 hover:text-primary-500 dark:text-gray-400 dark:hover:text-primary-400 transition-colors">
              <FaEnvelope size={24} />
            </a>
          </motion.div>
        </motion.div>
      </motion.div>
    </section>
  );
};

export default Hero;
