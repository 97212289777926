import { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Link, useLocation } from 'react-router-dom';
import { FaGithub, FaLinkedin, FaEnvelope, FaPhone, FaMapMarkerAlt, FaBars, FaTimes, FaSun, FaMoon, FaArrowRight, FaPaw, FaChevronDown, FaSearch } from 'react-icons/fa';

const Logo = () => (
  <motion.div 
    className="relative group cursor-pointer"
    whileHover={{ scale: 1.02 }}
    whileTap={{ scale: 0.98 }}
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ duration: 0.3 }}
  >
    <div className="absolute -inset-0.5 bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 dark:from-indigo-400 dark:via-purple-400 dark:to-pink-400 rounded-lg blur-sm opacity-20 group-hover:opacity-40 transition duration-300" />
    <div className="relative px-3 sm:px-3 md:px-4 py-1.5 sm:py-1.5 md:py-2 bg-white/95 dark:bg-gray-900/95 rounded-lg leading-none flex items-center backdrop-blur-sm">
      <span className="font-serif italic text-3xl sm:text-2xl md:text-3xl bg-gradient-to-r from-primary-500 to-violet-500 bg-clip-text text-transparent transition-colors duration-300">
        devyn
      </span>
      <span className="ml-1 text-xl sm:text-lg md:text-xl font-light tracking-wider text-gray-700 dark:text-gray-200 uppercase transition-colors duration-300">
        miller
      </span>
    </div>
  </motion.div>
);

const Navbar = ({ theme, toggleTheme }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const location = useLocation();
  const isBlogPost = location.pathname.startsWith('/blog/');

  const navItems = [
    { name: 'About', href: 'about', type: 'link' },
    { 
      name: 'Work', 
      type: 'dropdown',
      items: [
        { name: 'Experience', href: 'experience' },
        { name: 'Skills', href: 'skills' },
        { name: 'Projects', href: 'projects' }
      ]
    },
    { 
      name: 'Content', 
      type: 'dropdown',
      items: [
        { name: 'Writing', href: 'writing' },
        { name: 'Blog', href: 'blog' }
      ]
    },
    { name: 'Contact', href: 'contact', type: 'link' }
  ];

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const renderNavLink = (item) => {
    // On home page, we want smooth scrolling to sections
    if (location.pathname === '/' || location.pathname === '') {
      return (
        <Link
          to={`/#${item.href}`}
          className="text-gray-700 dark:text-gray-300 hover:text-primary-500 dark:hover:text-primary-400 
                   px-3 py-2 rounded-md text-lg font-medium cursor-pointer transition-colors"
          onClick={() => {
            setTimeout(() => {
              const element = document.getElementById(item.href);
              if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'start' });
              }
            }, 100);
            setIsOpen(false);
          }}
        >
          {item.name}
        </Link>
      );
    }

    // On other pages, we want to navigate to the respective routes
    return (
      <Link
        to={`/${item.href}`}
        className="text-gray-700 dark:text-gray-300 hover:text-primary-500 dark:hover:text-primary-400 
                 px-3 py-2 rounded-md text-lg font-medium cursor-pointer transition-colors"
        onClick={() => setIsOpen(false)}
      >
        {item.name}
      </Link>
    );
  };

  const renderNavItem = (item, index) => {
    if (item.type === 'dropdown') {
      return (
        <motion.div
          key={item.name}
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: index * 0.1 }}
          className="relative"
          onMouseEnter={() => setActiveDropdown(item.name)}
          onMouseLeave={() => setActiveDropdown(null)}
        >
          <button className="flex items-center text-gray-700 dark:text-gray-300 hover:text-primary-500 dark:hover:text-primary-400 
                          px-3 py-2 rounded-md text-lg font-medium cursor-pointer transition-colors">
            {item.name}
            <FaChevronDown className="ml-1 w-3 h-3" />
          </button>
          
          <AnimatePresence>
            {activeDropdown === item.name && (
              <motion.div
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 10 }}
                className="absolute left-0 mt-2 w-48 rounded-md shadow-lg bg-white dark:bg-gray-800 ring-1 ring-black ring-opacity-5 z-20"
              >
                <div className="py-1">
                  {item.items.map((subItem) => (
                    <div key={subItem.name} className="px-4 py-2">
                      {renderNavLink(subItem)}
                    </div>
                  ))}
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>
      );
    }

    return (
      <motion.div
        key={item.name}
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: index * 0.1 }}
      >
        {renderNavLink(item)}
      </motion.div>
    );
  };

  return (
    <nav className={`fixed w-full z-50 transition-all duration-300 ${
      scrolled ? 'bg-white/90 dark:bg-gray-900/90 backdrop-blur-sm shadow-lg' : 'bg-transparent'
    }`}>
      <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-6 overflow-visible">
        <div className="flex items-center justify-between h-14 sm:h-16 md:h-16">
          {/* Logo - visible on both mobile and desktop */}
          <div className="flex-shrink-0 ml-0 sm:ml-0 md:ml-0 lg:ml-0 xl:-ml-4">
            <Link to="/">
              <Logo />
            </Link>
          </div>

          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center justify-between flex-1 pl-2 md:pl-4 lg:pl-8">
            {/* Center - Main Navigation and Resume Button */}
            <div className="flex items-center space-x-3 md:space-x-4 lg:space-x-8 mx-auto">
              {navItems.map((item, index) => renderNavItem(item, index))}
              
              <motion.div
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <a
                  href="https://drive.google.com/file/d/1wayzgQrm0pB9XRAW9DaPbiamLT7pMkg9/view?usp=sharing" 
                  className="inline-flex items-center px-2 py-1 md:px-3 md:py-1.5 bg-primary-500 text-white rounded-2xl hover:bg-primary-600 
                           transition-colors duration-300 text-base md:text-lg font-medium"
                >
                  Resume
                  <FaArrowRight className="ml-2 w-4 h-4" />
                </a>
              </motion.div>
            </div>

            {/* Right side - Theme Toggle and Search */}
            <div className="flex items-center space-x-2 ml-auto mr-0 md:mr-0 lg:mr-0">
              <motion.button
                onClick={toggleTheme}
                className="inline-flex items-center p-2 rounded-lg text-gray-700 dark:text-gray-300 
                         hover:bg-gray-100 dark:hover:bg-gray-800 focus:outline-none focus:ring-2 
                         focus:ring-offset-2 focus:ring-primary-500 transition-colors duration-300"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                aria-label={theme === 'dark' ? 'Switch to Light Mode' : 'Switch to Dark Mode'}
              >
                {theme === 'dark' ? (
                  <>
                    <FaSun className="w-4 h-4" />
                    <span className="hidden lg:inline ml-2 text-base font-medium">Light</span>
                  </>
                ) : (
                  <>
                    <FaMoon className="w-4 h-4" />
                    <span className="hidden lg:inline ml-2 text-base font-medium">Dark</span>
                  </>
                )}
              </motion.button>
              
              <motion.button
                onClick={() => document.querySelector('.search-button').click()}
                className="inline-flex items-center px-2 md:px-3 lg:px-6 py-2 rounded-lg text-gray-700 dark:text-gray-300 
                         hover:bg-gray-100 dark:hover:bg-gray-800 focus:outline-none focus:ring-2 
                         focus:ring-offset-2 focus:ring-primary-500 transition-colors duration-300
                         bg-white/80 dark:bg-gray-800/80 backdrop-blur-sm
                         border border-gray-300 dark:border-gray-600 shadow-sm"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                aria-label="Search website"
              >
                <div className="flex items-center w-full">
                  <FaSearch className="w-4 h-4 text-primary-500 dark:text-primary-400" />
                  <span className="hidden lg:inline-block ml-3 text-base font-medium">Search</span>
                </div>
              </motion.button>
            </div>
          </div>

          {/* Mobile Navigation Buttons */}
          <div className="md:hidden flex items-center justify-end space-x-2">
            <motion.button
              onClick={() => document.querySelector('.search-button').click()}
              className="p-2.5 rounded-lg text-primary-600 dark:text-primary-400 bg-white/90 dark:bg-gray-800/90 
                       border border-primary-400 dark:border-primary-500 shadow-sm backdrop-blur-sm
                       focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              aria-label="Search website"
            >
              <FaSearch className="w-5 h-5" />
            </motion.button>
            
            <motion.button
              onClick={toggleTheme}
              className="p-2.5 rounded-lg text-white bg-primary-500 hover:bg-primary-600 shadow-sm
                       backdrop-blur-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              aria-label={theme === 'dark' ? 'Switch to Light Mode' : 'Switch to Dark Mode'}
              title={theme === 'dark' ? 'Switch to Light Mode' : 'Switch to Dark Mode'}
            >
              {theme === 'dark' ? <FaSun className="w-5 h-5" /> : <FaMoon className="w-5 h-5" />}
            </motion.button>
            
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="p-2.5 rounded-lg text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-800 
                       bg-white/90 dark:bg-gray-800/90 backdrop-blur-sm border border-gray-300 dark:border-gray-700
                       focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
              aria-expanded={isOpen}
              aria-label="Toggle navigation menu"
            >
              {isOpen ? <FaTimes className="w-5 h-5" /> : <FaBars className="w-5 h-5" />}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Navigation Menu */}
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            className="md:hidden bg-white/95 dark:bg-gray-900/95 shadow-lg backdrop-blur-sm overflow-y-auto max-h-[80vh]"
          >
            <div className="px-2 pt-4 pb-5 space-y-3">
              {navItems.map((item) => {
                if (item.type === 'dropdown') {
                  return (
                    <div key={item.name} className="space-y-1">
                      <div className="px-3 py-2 text-gray-700 dark:text-gray-300 text-base font-medium border-b border-gray-200 dark:border-gray-700">
                        {item.name}
                      </div>
                      {item.items.map((subItem) => (
                        <Link
                          key={subItem.name}
                          to={`/${subItem.href}`}
                          className="block px-6 py-2 text-gray-700 dark:text-gray-300 hover:text-primary-500 dark:hover:text-primary-400 
                                   text-base font-medium cursor-pointer"
                          onClick={() => {
                            setIsOpen(false);
                          }}
                        >
                          {subItem.name}
                        </Link>
                      ))}
                    </div>
                  );
                }
                return (
                  <Link
                    key={item.name}
                    to={`/${item.href}`}
                    className="block px-3 py-2 text-gray-700 dark:text-gray-300 hover:text-primary-500 dark:hover:text-primary-400 
                             text-base font-medium cursor-pointer"
                    onClick={() => {
                      setIsOpen(false);
                    }}
                  >
                    {item.name}
                  </Link>
                );
              })}
              
              <a
                href="https://drive.google.com/file/d/1wayzgQrm0pB9XRAW9DaPbiamLT7pMkg9/view?usp=sharing"
                className="flex items-center justify-center px-5 py-2.5 bg-primary-500 text-white rounded-lg hover:bg-primary-600 
                         shadow-sm transition-all duration-300 text-lg font-medium mt-4 mx-3"
                onClick={() => setIsOpen(false)}
              >
                Resume
                <FaArrowRight className="ml-2 w-4 h-4" />
              </a>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </nav>
  );
};

export default Navbar;
