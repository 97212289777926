import { useState, useEffect } from 'react';
import { HashRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { FaArrowUp } from 'react-icons/fa';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Search from './components/Search';
import BlogPost from './components/BlogPost';
import Books from './components/Books';
import ScrollToTopOnNavigate from './components/ScrollToTopOnNavigate';

// Import main Home component that contains all sections
import Home from './pages/Home';

const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', toggleVisibility);
    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.button
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 20 }}
          onClick={scrollToTop}
          className="fixed bottom-8 right-8 p-4 bg-primary-500 text-white rounded-full shadow-lg 
                   hover:bg-primary-600 transition-colors duration-300 z-50"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          <FaArrowUp className="w-6 h-6" />
        </motion.button>
      )}
    </AnimatePresence>
  );
};

// Custom component to handle Mira redirect
const MiraRedirect = () => {
  useEffect(() => {
    // Direct window location change to ensure proper navigation to the static HTML file
    window.location.href = "/mira/index.html";
  }, []);
  
  // Return a loading state while redirect happens
  return <div className="min-h-screen flex items-center justify-center bg-white dark:bg-gray-900">Redirecting to resume...</div>;
};

// Component to handle section scrolling
const ScrollToSection = ({ sectionId }) => {
  useEffect(() => {
    const element = document.getElementById(sectionId);
    if (element) {
      // Use requestAnimationFrame to ensure DOM has updated
      requestAnimationFrame(() => {
        // Get current scroll position
        const currentPosition = window.scrollY;
        const targetPosition = element.getBoundingClientRect().top + window.scrollY;
        
        // Only scroll if we're not already at the target position (within a small margin)
        if (Math.abs(currentPosition - targetPosition) > 50) {
          element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      });
    }
  }, [sectionId]);

  return <Home />;
};

function App() {
  const [theme, setTheme] = useState('light');

  useEffect(() => {
    if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
      setTheme('dark');
    }

    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const handleChange = (e) => setTheme(e.matches ? 'dark' : 'light');
    mediaQuery.addEventListener('change', handleChange);

    return () => mediaQuery.removeEventListener('change', handleChange);
  }, []);

  const toggleTheme = () => {
    setTheme(prevTheme => prevTheme === 'light' ? 'dark' : 'light');
  };

  return (
    <Router>
      <div className={`${theme}`}>
        <ScrollToTopOnNavigate />
        <Navbar theme={theme} toggleTheme={toggleTheme} />
        <AnimatePresence mode="wait">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<ScrollToSection sectionId="about" />} />
            <Route path="/experience" element={<ScrollToSection sectionId="experience" />} />
            <Route path="/skills" element={<ScrollToSection sectionId="skills" />} />
            <Route path="/projects" element={<ScrollToSection sectionId="projects" />} />
            <Route path="/writing" element={<ScrollToSection sectionId="writing" />} />
            <Route path="/blog" element={<ScrollToSection sectionId="blog" />} />
            <Route path="/contact" element={<ScrollToSection sectionId="contact" />} />
            <Route path="/blog/:postId" element={<BlogPost />} />
            <Route path="/mira" element={<MiraRedirect />} />
            <Route path="/books" element={<Books />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </AnimatePresence>
        <Footer />
        <ScrollToTop />
        <Search />
      </div>
    </Router>
  );
}

export default App;
