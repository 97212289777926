import { motion } from 'framer-motion';
import { useInView } from 'framer-motion';
import { useRef, useState } from 'react';
import { FaChevronDown, FaChevronRight, FaExternalLinkAlt } from 'react-icons/fa';

const Experience = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true, margin: "-100px" });

  const experiences = [
    {
      company: (
        <a href="https://cahb-lab.org/" target="_blank" rel="noopener noreferrer" className="text-primary-600 hover:underline">
          Laboratory for the Computational Analysis of Health Behavior (CAHB) <FaExternalLinkAlt className="inline ml-1" />
        </a>
      ),
      location: "Orange, CA",
      title: "Graduate Researcher",
      date: "March 2025–Present",
      research_areas: [
        {
          title: "Cognitive Fatigue and Multimodal Communication",
          date: "March 2025–Present",
          achievements: [
            "Investigating cognitive fatigue in Deaf and Hard-of-Hearing (DHH) bimodal bilinguals, analyzing impact of speechreading vs. sign language reliance on mental effort and well-being",
            "Conducting statistical analysis and data visualization for multi-phase survey study with 200+ DHH respondents, examining correlations between language modality switching and fatigue reduction",
            "Applying regression modeling to assess protective effects of American Sign Language (ASL) proficiency on listening-related fatigue, utilizing Principal Component Analysis (PCA) to identify cognitive and social fatigue factors",
            "Exploring modality switching as cognitive fatigue intervention for neurodiverse populations, including individuals with ADHD, dyslexia, and autism, informing potential public health applications",
            "Developing and optimizing data pipelines for processing large-scale survey datasets in R, ensuring statistical rigor and reproducibility in fatigue-related research",
          ]
        },
        {
          title: "Computational Health Psychology – Physical Activity and Longevity",
          date: "March 2025–Present",
          achievements: [
            "Analyzing large-scale post-COVID health data (provided by All of Us Research) to assess long-term physical activity trends, step count recommendations (8,000–10,000 steps daily), and their associations with longevity",
            "Studying meta-analyses on step count thresholds, identifying up to 56% mortality risk reductions for individuals meeting daily step targets",
            "Utilizing clustering techniques and time series analysis (e.g., K-means, DTW) to uncover patterns in physical activity data and improve clustering performance",
            "Exploring integration of wearable data (e.g., Fitbit) with health outcomes to understand impact of physical activity on long-term health"
          ]
        }
      ]
    },
    {
      company: (
        <a href="https://www.identiv.com/iot" target="_blank" rel="noopener noreferrer" className="text-primary-600 hover:underline">
          Identiv <FaExternalLinkAlt className="inline ml-1" />
        </a>
      ),
      location: "Santa Ana, CA",
      title: "Business Intelligence & Analytics Intern",
      date: "Feb 2025–Present",
      achievements: [
        "Developing 3-year ROI evaluation system using dynamic scoring, integrating qualitative and quantitative metrics to enhance strategic decision-making",
        "Designing marketing dashboards to track partner and association activities, categorizing KPIs such as lead generation, brand awareness, and engagement metrics",
        "Analyzing market trends in smart packaging, identifying industry growth opportunities and translating insights into actionable marketing strategies",
        "Assisting in executing and evaluating AI survey project, assessing lead quality from 200+ cold leads using data-driven scoring methodologies",
        "Creating visual reports and infographics to communicate technical insights effectively across presentations, social media, and marketing platforms"
      ]
    },
    {
      company: (
        <a href="https://www.chapman.edu/research/institutes-and-centers/economic-science-institute/index.aspx" target="_blank" rel="noopener noreferrer" className="text-primary-600 hover:underline">
          Economic Science Institute <FaExternalLinkAlt className="inline ml-1" />
        </a>
      ),
      location: "Orange, CA",
      title: "Graduate Research Assistant",
      date: "Aug 2023–Present",
      research_areas: [
        {
          title: "Drug Shortage Market Simulation",
          date: "Jan 2025–Present",
          achievements: [
            "Developing economic simulation environment that replicates real-world interactions between manufacturers, distributors, and end-users to analyze drug shortages",
            "Implementing game-theoretic models to assess how supply chain disruptions and distributor pricing strategies contribute to persistent shortages",
            "Testing seven policy interventions, including manufacturer penalties, government participation in price negotiations, and increased distributor storage capacity",
            "Optimizing simulation parameters to model real-world supply chain behavior and evaluate most cost-effective strategies for mitigating drug shortages",
            "Designing and running controlled laboratory experiments to examine pharmaceutical pricing dynamics and government policy impacts on drug availability"
          ]
        },
        {
          title: "Machine Learning for Work Allocation",
          date: "Aug 2023–May 2024",
          achievements: [
            "Collaborated with international, cross-functional teams using Git to rapidly iterate on software features and integrate stakeholder feedback for efficient work scheduling",
            "Applied Bayesian statistical modeling in Python and Stan to analyze worker allocation data, leading to 15% increase in scheduling efficiency",
            "Developed mixture models to optimize job satisfaction strategies using performance data from 143 participants, improving retention forecasting accuracy by 43%",
            "Engineered predictive modeling techniques for workforce planning, ensuring optimal utilization of human resources"
          ]
        },
        {
          title: "Healthcare Resource Optimization",
          date: "Nov 2023–May 2024",
          achievements: [
            "Refined 20+ years of healthcare resource allocation models using behavioral statistics, enhancing decision-making strategies over simulated 100-year lifetime",
            "Developed dynamic programming (DP) models for long-term healthcare planning, optimizing treatment pathways and cost-effectiveness",
            "Implemented parallel computing techniques using OpenMP to accelerate large-scale simulations for healthcare policy analysis",
            "Analyzed stochastic health shocks and their impact on resource allocation, integrating probability-based decision models to improve patient outcomes"
          ]
        }
      ]
    },
    {
      company: (
        <a href="https://www.chapman.edu/research/institutes-and-centers/leatherby-center/incubator-and-accelerator-programs.aspx" target="_blank" rel="noopener noreferrer" className="text-primary-600 hover:underline">
          InterPhase Incubator Entrepreneurship Program <FaExternalLinkAlt className="inline ml-1" />
        </a>
      ),
      location: "Orange, CA",
      title: "SaaS Developer and Business Architect",
      date: "Sept 2024–Dec 2024",
      achievements: [
        "Validated early-stage startup idea and developed SaaS application for optimizing muscle hypertrophy holistically integrating 15+ health metrics",
        "Integrated WESEE framework (Wellness, Education, Society, Ethics, Environment) to align with global challenges, ensuring data veracity when finetuning machine learning models",
        "Connected weekly with 10+ diverse ventures to enhance business strategies"
      ]
    },
    {
      company: (
        <a href="https://www.wyzant.com/" target="_blank" rel="noopener noreferrer" className="text-primary-600 hover:underline">
          Wyzant <FaExternalLinkAlt className="inline ml-1" />
        </a>
      ),
      location: "Orange, CA",
      title: "Tutor",
      date: "Dec 2023–Feb 2024",
      achievements: [
        "Coached K-12 & college students in variety of subjects, including economics, business, and mathematics"
      ]
    },
    {
      company: (
        <a href="https://www.fairmontschools.com/campuses/preparatory-academy/" target="_blank" rel="noopener noreferrer" className="text-primary-600 hover:underline">
          Fairmont Preparatory Academy <FaExternalLinkAlt className="inline ml-1" />
        </a>
      ),
      location: "Anaheim, CA",
      title: "Tutor, Teaching Assistant",
      date: "Feb 2019–Oct 2023",
      achievements: [
        "Tutored 150+ students annually in Precalculus and AP Calculus, achieving 95% AP exam pass rate by enhancing comprehension and test readiness",
        "Implemented Google Classroom and Canvas to streamline grading, communication, and resource distribution, significantly improving class workflow and accessibility",
        "Assisted faculty in designing and grading curriculum-aligned assessments, ensuring comprehensive AP exam preparation"
      ]
    },
    {
      company: (
        <a href="https://med.stanford.edu/odme/pre-med-students/summer-pre-med-programs.html" target="_blank" rel="noopener noreferrer" className="text-primary-600 hover:underline">
          Stanford University School of Medicine <FaExternalLinkAlt className="inline ml-1" />
        </a>
      ),
      location: "Stanford, CA",
      title: "Stanford Summer Community College Premedical Scholar",
      date: "June 2021 – July 2021",
      achievements: [
        "Selected for highly competitive premedical program hosted by Office of Diversity in Medical Education, receiving full scholarship to participate",
        "Engaged in intensive coursework and professional development workshops designed to prepare community college students for medical school",
        "Analyzed healthcare disparities and leadership challenges through interactive discussions, case studies, and expert-led seminars",
        "Developed science success strategies, critical thinking skills, and communication techniques essential for medical careers",
        "Received mentorship from Stanford medical students and faculty, gaining insights into medical school admissions, financing, and career pathways",
        "Strengthened leadership and teamwork abilities through collaborative problem-solving exercises in medical and public health contexts"
      ]
    },    
    {
      company: (
        <a href="https://preuss.ucsd.edu/" target="_blank" rel="noopener noreferrer" className="text-primary-600 hover:underline">
          Preuss School of UC San Diego <FaExternalLinkAlt className="inline ml-1" />
        </a>
      ),
      location: "La Jolla, CA",
      title: "Tutor",
      date: "Apr 2021-Jun 2021",
      achievements: [
        "Organized AP Literature discussion materials and collaborated with faculty to guide students through advanced coursework and AP Exam preparation"
      ]
    }
  ];

  return (
    <section id="experience" className="py-20 bg-gradient-to-b from-white to-sky-50 dark:from-gray-900 dark:to-gray-800 transition-colors duration-300">
      <div className="container mx-auto px-4">
        <motion.div
          ref={ref}
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: isInView ? 1 : 0, y: isInView ? 0 : 50 }}
          transition={{ duration: 0.8 }}
          className="max-w-4xl mx-auto"
        >
          <h2 className="text-3xl font-bold text-center text-gray-900 dark:text-white mb-12">
            Professional Experience
          </h2>

          <div className="space-y-12">
            {experiences.map((experience, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: isInView ? 1 : 0, x: isInView ? 0 : -50 }}
                transition={{ duration: 0.5, delay: 0.2 + index * 0.1 }}
                className="relative pl-8 border-l-2 border-primary-500"
              >
                <div className="absolute -left-2.5 top-0 w-5 h-5 bg-primary-500 rounded-full" />
                
                <div className="bg-gray-50 dark:bg-gray-800 rounded-lg p-6 shadow-md">
                  <div className="flex flex-wrap justify-between items-start mb-4">
                    <div>
                      <h3 className="text-xl font-semibold text-primary-600 dark:text-white">
                        {experience.company}
                      </h3>
                      <p className="text-lg font-medium text-primary-600 dark:text-primary-400">
                        {experience.title}
                      </p>
                    </div>
                    <div className="text-right">
                      <span className="text-sm text-gray-500 dark:text-gray-400">
                        {experience.location}
                      </span>
                      <p className="text-sm text-gray-500 dark:text-gray-400">
                        {experience.date}
                      </p>
                    </div>
                  </div>

                  {experience.research_areas ? (
                    <div className="space-y-6">
                      {experience.research_areas.map((area, areaIndex) => {
                        const [isOpen, setIsOpen] = useState(false);

                        return (
                          <div key={areaIndex} className="mt-4">
                            <h4
                              className="text-md font-semibold text-gray-800 dark:text-gray-200 mb-3 cursor-pointer flex justify-between items-center"
                              onClick={() => setIsOpen(!isOpen)}
                            >
                              <span>{area.title}</span>
                              {isOpen ? <FaChevronDown className="ml-2" /> : <FaChevronRight className="ml-2" />}
                            </h4>
                            {isOpen && (
                              <div className="flex justify-between items-center">
                                <span className="text-sm text-gray-500 dark:text-gray-400">{area.date}</span>
                                <ul className="space-y-3 text-gray-600 dark:text-gray-300 pl-4">
                                  {area.achievements.map((achievement, i) => (
                                    <li
                                      key={i}
                                      className="relative pl-6 before:content-['•'] before:absolute before:left-0 before:text-primary-500"
                                    >
                                      {achievement}
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <ul className="space-y-3 text-gray-600 dark:text-gray-300">
                      {experience.achievements.map((achievement, i) => (
                        <li
                          key={i}
                          className="relative pl-6 before:content-['•'] before:absolute before:left-0 before:text-primary-500"
                        >
                          {achievement}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </motion.div>
            ))}
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default Experience;
