import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Hero from '../components/Hero';
import About from '../components/About';
import Experience from '../components/Experience';
import Skills from '../components/Skills';
import Projects from '../components/Projects';
import Contact from '../components/Contact';
import TechnicalWriting from '../components/TechnicalWriting';
import Blog from '../components/Blog';

const Home = () => {
  const { hash, pathname } = useLocation();
  
  // Only handle direct hash changes when on the home route
  useEffect(() => {
    // Only process hash navigation on the home route
    // This prevents duplicate scrolling when navigating to specific routes
    if (pathname === '/' && hash) {
      const id = hash.replace('#', '');
      const element = document.getElementById(id);
      
      if (element) {
        // Use requestAnimationFrame to ensure DOM has updated
        requestAnimationFrame(() => {
          element.scrollIntoView({ behavior: 'smooth' });
        });
      }
    }
  }, [hash, pathname]);

  return (
    <main className="bg-white dark:bg-gray-900 transition-colors duration-300 min-h-screen">
      <Hero />
      <div id="about">
        <About />
      </div>
      <div id="experience">
        <Experience />
      </div>
      <div id="skills">
        <Skills />
      </div>
      <div id="projects">
        <Projects />
      </div>
      <div id="writing">
        <TechnicalWriting />
      </div>
      <div id="blog">
        <Blog />
      </div>
      <div id="contact">
        <Contact />
      </div>
    </main>
  );
};

export default Home; 