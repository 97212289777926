import { motion } from 'framer-motion';
import { FaClock, FaLinkedin, FaTwitter, FaArrowLeft } from 'react-icons/fa';
import { blogPosts, blogCategories, getRelatedPosts } from '../data/blog';
import { Link, useParams, useNavigate } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { useEffect } from 'react';
// import Newsletter from './Newsletter';

const SocialShare = ({ post }) => {
  const shareUrl = window.location.href;
  const text = `Check out "${post.title}" by Devyn Miller`;

  return (
    <div className="flex items-center space-x-4">
      <span className="text-gray-600 dark:text-white">Share:</span>
      <motion.a
        href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(shareUrl)}`}
        target="_blank"
        rel="noopener noreferrer"
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        className="text-linkedin hover:text-linkedin-dark dark:text-white"
      >
        <FaLinkedin className="w-5 h-5" />
      </motion.a>
      <motion.a
        href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(shareUrl)}&text=${encodeURIComponent(text)}`}
        target="_blank"
        rel="noopener noreferrer"
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        className="text-twitter hover:text-twitter-dark dark:text-white"
      >
        <FaTwitter className="w-5 h-5" />
      </motion.a>
    </div>
  );
};

const RelatedPost = ({ post }) => (
  <motion.div
    whileHover={{ scale: 1.02 }}
    className="bg-white dark:bg-gray-800 rounded-lg shadow p-4"
  >
    <h4 className="font-semibold text-gray-900 dark:text-gray-100 mb-2">
      {post.title}
    </h4>
    <p className="text-gray-600 dark:text-gray-400 text-sm mb-2">
      {post.preview}
    </p>
    <button className="text-primary-500 hover:text-primary-600 text-sm font-medium">
      Read More
    </button>
  </motion.div>
);

const BlogPost = () => {
  const { postId } = useParams();
  const navigate = useNavigate();
  const post = blogPosts.find(p => p.id === postId);
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [postId]);

  if (!post) {
    navigate('/');
    return null;
  }

  const relatedPosts = getRelatedPosts(post);

  return (
    <section className="min-h-screen py-20 bg-gradient-to-br from-primary-50 via-white to-sage-50 dark:from-gray-900 dark:via-gray-800 dark:to-gray-900">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Back Button */}
        <motion.div
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          className="mb-8"
        >
          <Link
            to="/blog"
            className="inline-flex items-center px-4 py-2 rounded-lg bg-white dark:bg-gray-800 shadow-sm
                     text-gray-700 dark:text-gray-300 hover:text-primary-500 dark:hover:text-primary-400
                     border border-gray-200 dark:border-gray-700 hover:border-primary-500 dark:hover:border-primary-400
                     transition-all duration-300"
          >
            <FaArrowLeft className="mr-2 w-4 h-4" />
            Back to Blog
          </Link>
        </motion.div>

        {/* Post Header */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="mb-8 bg-white dark:bg-gray-800 rounded-xl shadow-lg p-8"
        >
          <h1 className="text-4xl font-bold mb-4 bg-gradient-to-r from-primary-600 to-accent-500 dark:from-primary-400 dark:to-accent-400 text-transparent bg-clip-text">
            {post.title}
          </h1>
          
          <div className="flex flex-wrap items-center gap-4 mb-6">
            <div className="flex items-center">
              <FaClock className="text-primary-500 dark:text-primary-400 mr-2" />
              <span className="text-gray-600 dark:text-gray-400">{post.readTime}</span>
            </div>
            <div className="flex flex-wrap items-center gap-2">
              {post.categories.map((categoryId) => {
                const category = blogCategories.find(c => c.id === categoryId);
                return (
                  <span
                    key={categoryId}
                    className="inline-flex items-center px-3 py-1.5 rounded-full text-sm 
                             bg-primary-100 dark:bg-primary-900 text-primary-700 dark:text-primary-300
                             transition-colors duration-200 hover:bg-primary-200 dark:hover:bg-primary-800"
                  >
                    {category?.name}
                  </span>
                );
              })}
            </div>
          </div>

          <div className="prose prose-lg dark:prose-invert prose-primary max-w-none">
            <ReactMarkdown
              components={{
                h1: ({ node, ...props }) => <h1 className="text-3xl font-bold text-gray-900 dark:text-white mb-6" {...props} />,
                h2: ({ node, ...props }) => <h2 className="text-2xl font-semibold text-gray-900 dark:text-white mt-8 mb-4" {...props} />,
                p: ({ node, ...props }) => <p className="text-gray-800 dark:text-gray-200 mb-4 text-justify" {...props} />,
                ul: ({ node, ...props }) => <ul className="list-disc pl-6 mb-4" {...props} />,
                ol: ({ node, ...props }) => <ol className="list-decimal pl-6 mb-4" {...props} />,
                li: ({ node, ...props }) => <li className="text-gray-800 dark:text-gray-200 mb-2" {...props} />,
                strong: ({ node, ...props }) => <strong className="font-semibold text-gray-900 dark:text-white" {...props} />,
                img: ({ node, ...props }) => (
                  <img
                    {...props}
                    className="w-1/3 float-right rounded-lg shadow-md ml-4 mb-4"
                    alt={props.alt || "Blog image"}
                  />
                ),
              }}
            >
              {post.content}
            </ReactMarkdown>
          </div>

          {/* Social Share */}
          <div className="mt-8 pt-6 border-t border-gray-200 dark:border-gray-700">
            <SocialShare post={post} />
          </div>
        </motion.div>

        {/* Related Posts and Newsletter */}
        <div className="grid md:grid-cols-2 gap-8">
          {/* Related Posts */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
            className="bg-white dark:bg-gray-800 rounded-xl shadow-lg p-6"
          >
            <h3 className="text-xl font-semibold mb-4 text-gray-900 dark:text-gray-100">
              Related Posts
            </h3>
            <div className="space-y-4">
              {relatedPosts.map((relatedPost) => (
                <Link key={relatedPost.id} to={`/blog/${relatedPost.id}`}>
                  <RelatedPost post={relatedPost} />
                </Link>
              ))}
            </div>
          </motion.div>

          {/* Newsletter */}
          {/* <div className="mt-12">
            <Newsletter />
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default BlogPost;
