import React, { useRef, useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { FaArrowLeft, FaAmazon, FaBookmark, FaChevronRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import imGlad from '../assets/books/im-glad.jpg';
import holdMeTight from '../assets/books/hold-me-tight.jpg';
import mansSearch from '../assets/books/mans-search.jpg';
import friendsLovers from '../assets/books/friends-lovers.jpg';
import f451 from '../assets/books/f451.png';
import sevenHabits from '../assets/books/7-habits.jpg';
import howToWin from '../assets/books/how-to-win.jpg';
import loveTheoretically from '../assets/books/love-theoretically.jpg';
import harryPotter from '../assets/books/harry-potter.jpg';
import beyondTheWand from '../assets/books/beyond-the-wand.jpg';
import night from '../assets/books/night.jpg';
import coachWooden from '../assets/books/coach-wooden.jpg';

const Books = () => {
  const [activeCategory, setActiveCategory] = useState(null);
  const categoryRefs = useRef({});
  
  const books = [
    {
      title: "I'm Glad My Mom Died",
      author: "Jennette McCurdy",
      year: "2022",
      link: "https://www.amazon.com/Im-Glad-My-Mom-Died/dp/1982185821/",
      coverUrl: imGlad,
      category: "Memoir",
      description: "📖 This one hit really close to home. Even though I have an amazing mom, I deeply related to the way Jennette writes about trauma, control, and especially her experience with disordered eating. Her honesty is sharp but never self-pitying, and I found parts of my own story in hers. It's heartbreaking, but also empowering and even funny in a dark, clever way."
    },
    {
      title: "Hold Me Tight: Seven Conversations for a Lifetime of Love",
      author: "Dr. Sue Johnson",
      year: "2008",
      link: "https://www.amazon.com/Hold-Me-Tight-Conversations-Lifetime/dp/031611300X/",
      coverUrl: holdMeTight,
      category: "Relationships",
      description: "💙 My therapist recommended this to help me understand how attachment works, especially after growing up adopted. It helped me recognize how past trauma affects relationships today, and how emotional security really forms. I felt seen in ways I didn't expect. It's more than relationship advice; it's about learning how to connect and be vulnerable safely."
    },
    {
      title: "Man's Search for Meaning",
      author: "Viktor E. Frankl",
      year: "1946",
      link: "https://www.amazon.com/Mans-Search-Meaning-Viktor-Frankl/dp/0807014273/",
      coverUrl: mansSearch,
      category: "Philosophy",
      description: "🧠 This book changed how I think about purpose. Frankl's ability to find meaning, even while surviving a concentration camp, left me in awe. The psychological side of it really stuck with me too, especially his idea that we can't always control what happens, but we can choose how we respond. It's a perspective that shows up in how I think about AI and ethics too."
    },
    {
      title: "Friends, Lovers, and the Big Terrible Thing: A Memoir",
      author: "Matthew Perry",
      year: "2022",
      link: "https://www.amazon.com/Friends-Lovers-Big-Terrible-Thing/dp/1250866456/",
      coverUrl: friendsLovers,
      category: "Memoir",
      description: "🎭 I didn't expect to relate so much, but I did. Matthew Perry's sense of humor makes even the heavy parts feel bearable. His voice is funny, flawed, real—and that made it easier to sit with the pain he shares. It reminded me how much humor can coexist with trauma, and how much connection matters."
    },
    {
      title: "Fahrenheit 451",
      author: "Ray Bradbury",
      year: "1953",
      link: "https://www.amazon.com/Fahrenheit-451-Ray-Bradbury/dp/1451673310/",
      coverUrl: f451,
      category: "Fiction",
      description: "🔥 \"It was a pleasure to burn.\" That line still echoes in my head. The seashells, the Phoenix, the eerie parallels to our world today. It's wild how relevant this book still feels. I kept thinking about it while working on my BLE-based IoT project. It's like Bradbury saw the future and handed us a warning."
    },
    {
      title: "The 7 Habits of Highly Effective People",
      author: "Stephen Covey",
      year: "1989",
      link: "https://www.amazon.com/Habits-Highly-Effective-People-Powerful/dp/1982137274/",
      coverUrl: sevenHabits,
      category: "Self-Improvement",
      description: "📘 Focuses on character, values, and intentional living. My parents had me read this in middle school. At the time, I didn't realize how much of it would stick, but it quietly shaped how I think about responsibility, focus, and integrity. It's on my to-read-again list."
    },
    {
      title: "How to Win Friends & Influence People",
      author: "Dale Carnegie",
      year: "1936",
      link: "https://www.amazon.com/How-Win-Friends-Influence-People/dp/0671027034/",
      coverUrl: howToWin,
      category: "Self-Improvement",
      description: "💬 A social skills classic for a reason. Every time I flip through it, I'm reminded of how timeless the core ideas are: how to listen better, speak more kindly, and really understand people. I read it a while ago, and it's definitely another book I plan on re-reading."
    },
    {
      title: "Love, Theoretically",
      author: "Ali Hazelwood",
      year: "2023",
      link: "https://www.amazon.com/Love-Theoretically-Ali-Hazelwood/dp/0593336860",
      coverUrl: loveTheoretically,
      category: "Fiction",
      description: "🧪 I read this over winter break and it was just pure joy. It's playful and STEM-nerdy in the best way and exactly the kind of light, feel-good romcom I needed. I loved how smart and silly it was at the same time. If you're into academia with a romantic twist, this one's a win."
    },
    {
      title: "Harry Potter Series",
      author: "J.K. Rowling",
      year: "1997-2007",
      link: "https://www.amazon.com/Harry-Potter-Paperback-Box-Books/dp/0545162076/",
      coverUrl: harryPotter,
      category: "Fiction",
      description: "⚡ I remember picking up Book 7 at the library when I was 8 because it looked massive and cool. (Then I realized I really needed to start from the beginning.) I binge-read the whole series. These books were magic to me, and they still are. They're part of my childhood in the best way."
    },
    {
      title: "Beyond the Wand: The Magic and Mayhem of Growing Up a Wizard",
      author: "Tom Felton",
      year: "2023",
      link: "https://www.amazon.com/Beyond-Wand-Mayhem-Growing-Wizard/dp/1538741377/",
      coverUrl: beyondTheWand,
      category: "Memoir",
      description: "🎬 As a huge Harry Potter fan, I had to read this. But what I loved most was hearing about Tom's real life beyond the screen. His honesty about fame, identity, and mental health made me respect him even more. One quote that really stuck with me: \"The only true currency we have in life is the effect on those we have around us.\""
    },
    {
      title: "Night",
      author: "Elie Wiesel",
      year: "1960",
      link: "https://www.amazon.com/Night-Elie-Wiesel/dp/0374500010/",
      coverUrl: night,
      category: "Memoir",
      description: (
        <span>
          🌑 This one shook me. I've read it more than once, and each time it brings me back to that quiet, visceral grief that stays under your skin. Wiesel's storytelling doesn't scream.. it whispers, which somehow makes it even more haunting. After reading it, I ended up diving into the Nuremberg Trials, the Milgram experiment, and the Minnesota Starvation Experiment. It also opened the door to other survivor accounts, like <a href='https://www.amazon.com/s?k=boy+on+the+wooden+box&crid=3ODU66JXDOOPK&sprefix=boy+on+the%2Caps%2C192&ref=nb_sb_ss_ts-doa-p_2_10' target='_blank' rel='noopener noreferrer' className="text-blue-500 underline">The Boy on the Wooden Box by Leon Leyson (2013)</a> and <a href='https://www.amazon.com/Nazi-Officers-Wife-Survived-Holocaust/dp/0062378082/ref=sr_1_1?crid=WL7OQVB0DYJB&dib=eyJ2IjoiMSJ9.YMEEFc1uiEBQIl30v8f5fj4_ANNq1g_0QRGsxqiaXvZc1A6TzcmZtnzSEWf5MOtvo5cliHHyCJzUSnrgsti7sLdN6XK3qYWcR4b2hrbXYNcH5b5cNklfnjlyK6tIw2PeuRqIRLsKhY6ILTmJDExOQ-heRK77kVlu3e7moSQxXVrNvEncmTZrAs5db8HO_iA5qIAnGkAj_NsKCk3iKMdIPKGRtpuF3-HDRjMG67tHWwo.003pVGvuTyks9kSn5n8EZ_AItaXmqBFCgI5dNljG5tw&dib_tag=se&keywords=the+nazi+officers+wife&qid=1742748640&sprefix=the+nazi+off%2Caps%2C192&sr=8-1' target='_blank' rel='noopener noreferrer' className="text-blue-500 underline">The Nazi Officer's Wife by Edith Hahn-Beer (1999)</a>. It made me realize how much of modern trauma psychology and ethics trace back to what survivors like Wiesel lived through and why their voices still matter so much today.
        </span>
      )
    },
    {
      title: "Coach Wooden's Pyramid of Success",
      author: "John Wooden",
      year: "2005",
      link: "https://www.amazon.com/Coach-Woodens-Pyramid-Success-Playbook/dp/0800726251/",
      coverUrl: coachWooden,
      category: "Self-Improvement",
      description: "🏆 My dad had me memorize parts of this when I was four. \"Industriousness\" was the first block I ever learned. I don't remember all of it now, but it really shaped how I approach effort, focus, and consistency. I've been thinking about re-learning it as an adult, because the values in it are still a foundation for how I want to live."
    }
  ];

  // Group books by category
  const categories = [...new Set(books.map(book => book.category))];
  const booksByCategory = {};
  
  categories.forEach(category => {
    booksByCategory[category] = books.filter(book => book.category === category);
  });

  // Set up intersection observer to highlight the active category in navbar
  useEffect(() => {
    const observerOptions = {
      rootMargin: '-10% 0px -80% 0px',
      threshold: 0.1
    };

    const observerCallback = (entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setActiveCategory(entry.target.dataset.category);
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);
    
    // Observe each category section
    Object.keys(categoryRefs.current).forEach(category => {
      if (categoryRefs.current[category]) {
        observer.observe(categoryRefs.current[category]);
      }
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  const scrollToCategory = (category) => {
    if (categoryRefs.current[category]) {
      categoryRefs.current[category].scrollIntoView({
        behavior: 'smooth', 
        block: 'start'
      });
      setActiveCategory(category);
    }
  };

  const fadeInUpVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { duration: 0.5 }
    }
  };

  const staggerChildrenVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  return (
    <section className="py-20 bg-gradient-to-b from-white to-sky-50 dark:from-gray-900 dark:to-gray-800 min-h-screen transition-colors duration-300">
      <div className="container mx-auto px-4">
        <div className="max-w-5xl mx-auto">
          <div className="mb-6">
            <Link to="/#about" className="inline-flex items-center gap-2 text-primary-500 hover:text-primary-600 mb-6">
              <FaArrowLeft />
              <span>Back to About</span>
            </Link>
            
            <motion.h1 
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              className="text-4xl font-bold text-gray-900 dark:text-white text-center mb-4"
            >
              My Book Recommendations
            </motion.h1>
            <motion.div
              initial={{ width: 0 }}
              animate={{ width: '6rem' }}
              transition={{ duration: 0.5 }}
              className="h-1 bg-primary-500 mx-auto mb-8"
            />
            <motion.p
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.3 }}
              className="text-lg text-gray-600 dark:text-gray-300 text-center max-w-2xl mx-auto"
            >
              Here are some books that have impacted me, inspired me, or that I simply love. Click on any book to check it out on Amazon!
            </motion.p>
          </div>

          {/* Quick Links Navigation */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.4 }}
            className="mb-12"
          >
            <div className="bg-white/80 dark:bg-gray-800/80 backdrop-blur-sm p-6 rounded-xl shadow-md sticky top-20 z-10">
              <h2 className="text-xl font-bold text-gray-900 dark:text-white mb-4 flex items-center">
                <FaBookmark className="mr-2 text-primary-500" />
                Jump to Category
              </h2>
              <div className="flex flex-wrap gap-3">
                {categories.map((category) => (
                  <button
                    key={category}
                    onClick={() => scrollToCategory(category)}
                    className={`px-4 py-2 rounded-full text-sm font-medium transition-all duration-300 ${
                      activeCategory === category 
                        ? 'bg-primary-500 text-white shadow-md' 
                        : 'bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-600'
                    }`}
                  >
                    {category}
                  </button>
                ))}
              </div>
            </div>
          </motion.div>

          {categories.map((category, index) => (
            <motion.div 
              key={category}
              variants={fadeInUpVariants}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, margin: "-50px" }}
              className="mb-16"
              ref={el => categoryRefs.current[category] = el}
              data-category={category}
            >
              <div className="flex items-center gap-3 mb-8">
                <h2 className="text-2xl font-bold text-gray-900 dark:text-white">{category}</h2>
                <div className="flex-grow h-px bg-gray-200 dark:bg-gray-700"></div>
              </div>
              
              <motion.div 
                variants={staggerChildrenVariants}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                className="grid grid-cols-1 gap-8"
              >
                {booksByCategory[category].map((book, bookIndex) => (
                  <motion.div
                    key={`${book.title}-${bookIndex}`}
                    variants={fadeInUpVariants}
                    className="bg-white dark:bg-gray-800 rounded-lg overflow-hidden shadow-md hover:shadow-xl transition-all duration-300"
                  >
                    <div className="flex flex-col md:flex-row">
                      <a
                        href={book.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="md:w-1/4 h-64 md:h-auto overflow-hidden bg-gray-100 dark:bg-gray-700 flex-shrink-0"
                      >
                        <img 
                          src={book.coverUrl} 
                          alt={`Cover of ${book.title}`}
                          className="w-full h-full object-contain hover:scale-105 transition-transform duration-300"
                        />
                      </a>
                      <div className="p-5 md:w-3/4">
                        <div className="flex justify-between items-start mb-3">
                          <div>
                            <h3 className="text-xl font-bold text-gray-900 dark:text-white mb-1">{book.title}</h3>
                            <p className="text-sm text-gray-600 dark:text-gray-400">
                              by {book.author} ({book.year})
                            </p>
                          </div>
                          <a 
                            href={book.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-amazon hover:text-amazon-hover text-2xl"
                          >
                            <FaAmazon />
                          </a>
                        </div>
                        <p className="text-gray-700 dark:text-gray-300 mt-4">
                          {book.description}
                        </p>
                        <div className="mt-4 flex justify-between items-center">
                          <span className="text-xs bg-primary-100 dark:bg-primary-900 text-primary-800 dark:text-primary-300 px-2 py-1 rounded-full">
                            {book.category}
                          </span>
                          <a 
                            href={book.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="inline-flex items-center text-primary-500 hover:text-primary-600 text-sm font-medium"
                          >
                            <span>View on Amazon</span>
                            <FaChevronRight className="ml-1 text-xs" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </motion.div>
                ))}
              </motion.div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Books; 