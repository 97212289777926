import { FaGithub, FaLinkedin } from 'react-icons/fa';
import { personalInfo } from '../data/personal';
import { Link } from 'react-router-dom';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-white dark:bg-gray-900 transition-colors duration-300">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {/* Brand */}
          <div className="text-center md:text-left">
            <h3 className="text-xl font-display font-bold text-gray-900 dark:text-white mb-4">
              {personalInfo.name}
            </h3>
            <p className="text-gray-600 dark:text-gray-400">
              {personalInfo.shortBio}
            </p>
          </div>

          {/* Quick Links */}
          <div className="text-center">
            <h3 className="text-xl font-display font-bold text-gray-900 dark:text-white mb-4">
              Quick Links
            </h3>
            <nav className="space-y-3">
              <Link
                to="/about"
                className="block text-gray-600 dark:text-gray-400 hover:text-primary-500 
                         dark:hover:text-primary-400 transition-colors duration-300"
              >
                About
              </Link>
              <Link
                to="/experience"
                className="block text-gray-600 dark:text-gray-400 hover:text-primary-500 
                         dark:hover:text-primary-400 transition-colors duration-300"
              >
                Experience
              </Link>
              <Link
                to="/skills"
                className="block text-gray-600 dark:text-gray-400 hover:text-primary-500 
                         dark:hover:text-primary-400 transition-colors duration-300"
              >
                Skills
              </Link>
              <Link
                to="/projects"
                className="block text-gray-600 dark:text-gray-400 hover:text-primary-500 
                         dark:hover:text-primary-400 transition-colors duration-300"
              >
                Projects
              </Link>
              <Link
                to="/writing"
                className="block text-gray-600 dark:text-gray-400 hover:text-primary-500 
                         dark:hover:text-primary-400 transition-colors duration-300"
              >
                Writing
              </Link>
              <Link
                to="/blog"
                className="block text-gray-600 dark:text-gray-400 hover:text-primary-500 
                         dark:hover:text-primary-400 transition-colors duration-300"
              >
                Blog
              </Link>
              <Link
                to="/contact"
                className="block text-gray-600 dark:text-gray-400 hover:text-primary-500 
                         dark:hover:text-primary-400 transition-colors duration-300"
              >
                Contact
              </Link>
            </nav>
          </div>

          {/* Social Links */}
          <div className="text-center md:text-right">
            <h3 className="text-xl font-display font-bold text-gray-900 dark:text-white mb-4">
              Connect
            </h3>
            <div className="flex justify-center md:justify-end space-x-4">
              <a
                href={personalInfo.social.github}
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-600 dark:text-gray-400 hover:text-primary-500 
                         dark:hover:text-primary-400 transition-colors duration-300"
                aria-label="GitHub"
              >
                <FaGithub className="h-6 w-6" />
              </a>
              <a
                href={personalInfo.social.linkedin}
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-600 dark:text-gray-400 hover:text-primary-500 
                         dark:hover:text-primary-400 transition-colors duration-300"
                aria-label="LinkedIn"
              >
                <FaLinkedin className="h-6 w-6" />

              </a>
            </div>
          </div>
        </div>

        <div className="mt-12 pt-8 border-t border-gray-200 dark:border-gray-700">
          <p className="text-center text-gray-600 dark:text-gray-400">
            © {currentYear} {personalInfo.name}. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
