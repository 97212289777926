import { useState, useMemo } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { blogPosts } from '../data/blog';
import { FaCalendar, FaChevronRight } from 'react-icons/fa';

const BlogArchive = () => {
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);

  const archives = useMemo(() => {
    const archiveMap = {};
    
    blogPosts.forEach(post => {
      const date = new Date(post.date);
      const year = date.getFullYear();
      const month = date.getMonth();
      
      if (!archiveMap[year]) {
        archiveMap[year] = {};
      }
      if (!archiveMap[year][month]) {
        archiveMap[year][month] = [];
      }
      archiveMap[year][month].push(post);
    });

    return Object.entries(archiveMap)
      .sort(([yearA], [yearB]) => Number(yearB) - Number(yearA))
      .map(([year, months]) => ({
        year: Number(year),
        months: Object.entries(months)
          .sort(([monthA], [monthB]) => Number(monthB) - Number(monthA))
          .map(([month, posts]) => ({
            month: Number(month),
            posts: posts.sort((a, b) => new Date(b.date) - new Date(a.date))
          }))
      }));
  }, []);

  const monthNames = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  return (
    <div className="space-y-4">
      {archives.map(({ year, months }) => (
        <div key={year} className="border-b border-gray-200 dark:border-gray-700 last:border-0">
          <button
            onClick={() => setSelectedYear(selectedYear === year ? null : year)}
            className="w-full py-2 px-4 flex items-center justify-between text-left hover:bg-gray-50 dark:hover:bg-gray-800 rounded-lg transition-colors duration-200"
          >
            <span className="text-lg font-semibold text-gray-900 dark:text-gray-100">
              {year}
            </span>
            <motion.span
              animate={{ rotate: selectedYear === year ? 90 : 0 }}
              transition={{ duration: 0.2 }}
            >
              <FaChevronRight className="w-4 h-4 text-gray-500" />
            </motion.span>
          </button>
          
          {selectedYear === year && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              className="ml-4 space-y-2"
            >
              {months.map(({ month, posts }) => (
                <div key={month} className="border-l-2 border-gray-200 dark:border-gray-700 pl-4">
                  <button
                    onClick={() => setSelectedMonth(selectedMonth === month ? null : month)}
                    className="w-full py-2 px-4 flex items-center justify-between text-left hover:bg-gray-50 dark:hover:bg-gray-800 rounded-lg transition-colors duration-200"
                  >
                    <span className="text-gray-700 dark:text-gray-300">
                      {monthNames[month]} ({posts.length})
                    </span>
                    <motion.span
                      animate={{ rotate: selectedMonth === month ? 90 : 0 }}
                      transition={{ duration: 0.2 }}
                    >
                      <FaChevronRight className="w-3 h-3 text-gray-400" />
                    </motion.span>
                  </button>
                  
                  {selectedMonth === month && (
                    <motion.div
                      initial={{ opacity: 0, height: 0 }}
                      animate={{ opacity: 1, height: 'auto' }}
                      exit={{ opacity: 0, height: 0 }}
                      className="ml-4 space-y-2 py-2"
                    >
                      {posts.map(post => (
                        <Link
                          key={post.id}
                          to={`/blog/${post.id}`}
                          className="block py-2 px-4 hover:bg-gray-50 dark:hover:bg-gray-800 rounded-lg transition-colors duration-200"
                        >
                          <div className="text-gray-900 dark:text-gray-100 font-medium">
                            {post.title}
                          </div>
                          <div className="text-sm text-gray-500 dark:text-gray-400 flex items-center mt-1">
                            <FaCalendar className="w-3 h-3 mr-2" />
                            {new Date(post.date).toLocaleDateString()}
                          </div>
                        </Link>
                      ))}
                    </motion.div>
                  )}
                </div>
              ))}
            </motion.div>
          )}
        </div>
      ))}
    </div>
  );
};

export default BlogArchive;
